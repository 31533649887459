// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import styles
import "../assets/styles/containerStyles/contact.scss";

// Import components
import {InputGroup} from "../components/uiElements/inputGroup";
import PageWrapper from '../components/pageContentViews/pageWrapper'
import {LinkButton} from "../components/buttons/buttons";
import {LanguageTabs} from "../components/uiElements/Tabs";

// Import utils
import {mapStateToProps} from "../redux/mapStateToProps";
import {GetContact, UpdateContact} from "../redux/actions";
import {ContactAddingList} from "../constants/contactAddingList";
import {
    changeStateField,
    getInputValues,
    initTranslations,
    getTranslatableInputValues, getTranslationData, toggleStateField
} from "../utils/helperFunctions";
import {validateTranslations} from "../utils/validateTranslations";
import {Editor} from "../components/uiElements/Editor";
import {generateFileMediaUrl} from "../utils/generateMemberMediaUrl";
import {ACCEPT_IMAGE_TYPES} from "../constants/acceptedTypes";
import MediaSelectorModal from "../components/media/MediaSelectorModal";

export class Contact extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = ['address', 'privacyPolicy',];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                backgroundImage: undefined,
                email: "",
                phoneNumber: "",
                latitude: "",
                longitude: "",
                phoneNumber2: "",
                facebookUrl: "",
                linkedinUrl: "",
                instagramUrl: "",
                // twitterUrl: "",
            },
            isEditing: true,
            errorsTabs: [],
            errors: {},
            languageTab: props.mainLanguage,
        };
        this.errorsSet = new Set();
        this.errorsTabs = new Set();
        this.updatedDataMap = new Map();
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslatableInputValues = getTranslatableInputValues.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.updateContact = this.updateContact.bind(this);
        this.getMedia = this.getMedia.bind(this);
    }


    async componentDidMount() {
        !this.props?.contactData?.id && await this.props.GetContact();
        const {contactData} = this.props;
        const {fieldsData} = this.state;

        const translationsData = {};
        Object.keys(fieldsData.translations).forEach(key => {
            const trItem = contactData?.translations?.find(item => item.language === key);
            translationsData[key] = {
                ...fieldsData.translations[key],
                address: trItem?.address || '',
                privacyPolicy: trItem?.privacyPolicy || '',
            };
        });
        contactData && this.setState({
            fieldsData: {
                email: contactData.email || "",
                backgroundImage: contactData?.backgroundImage,
                latitude: contactData?.location?.latitude || "",
                longitude: contactData?.location?.longitude || "",
                phoneNumber: contactData.phoneNumber || "",
                phoneNumber2: contactData.phoneNumber2 || "",
                facebookUrl: contactData.facebookUrl || "",
                linkedinUrl: contactData.linkedinUrl || "",
                instagramUrl: contactData.instagramUrl || "",
                // twitterUrl: contactData.twitterUrl || "",
                translations: translationsData
            },
        });

    }

    getMedia(mediaArray){
        const { fieldsData, errors, isEditing } = this.state;
        if ( isEditing ) {
            this.currentData?.backgroundImage?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('backgroundImage', mediaArray[0]?.id)
                : this.updatedDataMap.delete('backgroundImage');
        }

        this.setState({
            fieldsData: {
                ...fieldsData,
                backgroundImage: mediaArray[0],
            },
            errors: {
                ...errors,
                backgroundImage: false
            }
        })
    }

    validateFields(constacData) {
        const translationRequiredFields = ['address', 'privacyPolicy']
        const validationTr = validateTranslations(constacData.translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = {...validationTr.errors};
        const phoneNumberRegExp = /^[+]?[0-9]{9,20}$/;
        if (constacData?.phoneNumber?.length && !phoneNumberRegExp.test(constacData.phoneNumber)) {
            errors.phoneNumber = true;
            result = false;
        }
        if ( !constacData?.backgroundImage ) {
            errors.backgroundImage = true;
            result = false;
        }
        if (!result) {
            this.setState({
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
        return result;
    }

    updateContact() {
        const {fieldsData} = this.state;
        const {translations} = fieldsData;
        if (this.validateFields(fieldsData)) {
            let TR_data = getTranslationData(translations)

            let reqData = {
                email: fieldsData.email,
                backgroundImage: fieldsData.backgroundImage?.id,
                phoneNumber: fieldsData.phoneNumber,
                phoneNumber2: fieldsData.phoneNumber2,
                facebookUrl: fieldsData.facebookUrl,
                linkedinUrl: fieldsData.linkedinUrl,
                instagramUrl: fieldsData.instagramUrl,
                // twitterUrl: fieldsData.twitterUrl,
                translations: TR_data,
                location: {
                    latitude: fieldsData.latitude,
                    longitude: fieldsData.longitude,
                }
            };
            //console.log('reqData', reqData);
            //
            this.props.UpdateContact(reqData).then(() => {
                this.updatedDataMap.clear();
                this.setState({
                    errors: {},
                    errorsTabs: []
                })
            })
        }
    }

    render() {
        const {requestLoading, contactData: contact} = this.props;
        const {fieldsData, languageTab, errors, errorsTabs, mediaModalOpen} = this.state;

        const {translations} = fieldsData;
        const initTranslations = contact?.translations?.find(lg => lg.language === languageTab) || {};

        const trData = translations[languageTab] || {};
        return <PageWrapper pageTitle={'Contact'}>
            <section className="contact">
                {/*<div className="top-side">*/}
                {/*    <label>Նկար <span className={'required-badge'}>*</span></label>*/}
                {/*    <LinkButton title="Ընտրել մեդիադարանից"*/}
                {/*                className={`media-select-btn ${errors.backgroundImage ? 'media-select-error' : ''}`}*/}
                {/*                cb={this.toggleMediaModal}/>*/}
                {/*</div>*/}
                {/*{fieldsData?.backgroundImage && <div className={'background-image'}>*/}
                {/*    <img src={generateFileMediaUrl(fieldsData?.backgroundImage?.path)} alt={''}/>*/}
                {/*</div>}*/}
                <div className="info-adding-fields">
                    {ContactAddingList.slice(0, 4).map((item, index) => {
                        return <InputGroup key={index}
                                           {...item}
                                           error={errors[item.name]}
                                           value={fieldsData?.[item.name]}
                                           initValue={contact?.[item.name]}
                                           onChange={this.getInputValues}/>
                    })}
                    <div className="social-links">
                        {ContactAddingList.slice(4).map((item, index) => {
                            return <InputGroup key={index}
                                               {...item}
                                               error={errors[item.name]}
                                               value={fieldsData?.[item.name]}
                                               initValue={contact?.[item.name]}
                                               onChange={this.getInputValues}/>
                        })}
                    </div>
                </div>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>
                <InputGroup inputType="input"
                            type="text"
                            label={'Հասցե'}
                            name="address"
                            maxLength={100}
                            value={trData?.address}
                            required={true}
                            initValue={initTranslations?.address}
                            error={errors['address' + languageTab]}
                            onChange={this.getTranslatableInputValues}/>
                <div className="editor-wrapper">
                    <label>Գաղտնիության քաղաքականություն <span className={'required-badge'}>*</span></label>
                    <Editor value={trData?.privacyPolicy || ''}
                            name={'privacyPolicy'}
                            initValue={initTranslations?.privacyPolicy}
                            error={errors['privacyPolicy' + languageTab]}
                            onChange={this.getTranslatableInputValues}/>
                </div>
{/*                <InputGroup inputType="textarea"
                            label={<>Գաղտնիության քաղաքականություն<span
                                className="field-required">*</span></>}
                            name="privacyPolicy"
                            value={trData.privacyPolicy}
                            initValue={initTranslations?.privacyPolicy}
                            error={errors['privacyPolicy' + languageTab]}
                            onChange={this.getTranslatableInputValues}/>*/}
                <div className="flex-wrapper-right">
                    <LinkButton title="Պահպանել"
                                disabled={!this.updatedDataMap.size}
                                loading={requestLoading}
                                cb={this.updateContact}/>
                </div>
            </section>
            {/*<MediaSelectorModal*/}
            {/*    isOpen={!!mediaModalOpen}*/}
            {/*    acceptTypes={ACCEPT_IMAGE_TYPES}*/}
            {/*    getMedia={this.getMedia}*/}
            {/*    closeModal={this.toggleMediaModal}*/}
            {/*/>*/}
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetContact,
    UpdateContact,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
