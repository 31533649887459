// Import packages
import React from "react";
import {connect} from "react-redux";

// Import utils
import {mapStateToProps} from "../redux/mapStateToProps";

// Import styles
import "../assets/styles/containerStyles/notFound.scss";

function NotFound(props) {
    const {leftSideMenuOpen} = props;
   //  const URL = "https://c1.staticflickr.com/5/4052/4503898393_303cfbc9fd_b.jpg"
   //
   //  const MAP = {
   //      name: "my-map",
   //      areas: [
   //          {
   //              name: "1",
   //              shape: "poly",
   //              coords: [25, 33, 27, 300, 128, 240, 128, 94],
   //              preFillColor: "green",
   //              fillColor: "blue"
   //          },
   //          {name: "2", shape: "poly", coords: [219, 118, 220, 210, 283, 210, 284, 119], preFillColor: "pink"},
   //          {name: "3", shape: "poly", coords: [381, 241, 383, 94, 462, 53, 457, 282], fillColor: "yellow"},
   //          {name: "4", shape: "poly", coords: [245, 285, 290, 285, 274, 239, 249, 238], preFillColor: "red"},
   //          {name: "5", shape: "circle", coords: [170, 100, 25]},
   //      ]
   //  }
   //
   //  function enterArea(area) {
   //      //this.setState({ hoveredArea: area });
   //  }
   //
   // function leaveArea(area) {
   //      //this.setState({ hoveredArea: null });
   //  }
   //
   //  function getTipPosition(area) {
   //      return { top: `${area.center[1]}px`, left: `${area.center[0]}px` };
   //  }
    return <div className={`not-found admin-inner-component ${leftSideMenuOpen ? "size-sm" : ""}`}>
        <div className="inner-content">
      {/*      <ImageMapper src={URL} map={MAP} width={500}
                         onLoad={() => {}}
                         onClick={area => {}}
                         onMouseEnter={area => enterArea(area)}
                         onMouseLeave={area => leaveArea(area)}
                         onMouseMove={(area, _, evt) => {}}
                         onImageClick={evt => {}}
                         onImageMouseMove={evt => {}}
            />*/}
           {/* {
                this.state.hoveredArea &&
                <span className="tooltip"
                      style={{...this.getTipPosition(this.state.hoveredArea)}}>
    		{this.state.hoveredArea && this.state.hoveredArea.name}
    	</span>
            }*/}
            <span className="number">404</span>
            <p className="lost-text">This page doesn't exist. It looks like you're lost.</p>
        </div>
    </div>
}

export default connect(mapStateToProps)(NotFound);
