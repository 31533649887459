import {history} from "../../configs/history";
import {request, _urlProjects} from "../api";
import {PROJECT_CONSTS, UTIL_CONSTS} from "../constants";
import {notificationSuccess} from "../../components/uiElements/Notifications";

export const GetProjects = () => {
    const requestData = {
        url: _urlProjects,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res.data;
                data && await dispatch({
                    type: PROJECT_CONSTS.GET_PROJECTS,
                    payload: data,
                });
            })
    }
};
export const GetProjectById = (id) => {
    const requestData = {
        url: `${_urlProjects}/${id}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res.data;
                data && await dispatch({
                    type: PROJECT_CONSTS.GET_PROJECT_BY_ID,
                    payload: data,
                });
            })
    }
};


export const UpdateProjectData = (data, id) => {
    const requestData = {
        url: `${_urlProjects}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING,
        });
        return request(requestData)
            .then(() => {
                notificationSuccess({
                    description: "Նախագիծը հաջողությամբ փոփոխվեց!"
                })
                history.push("/dashboard")
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING,
                });
            })
    }
};


/*----------- Blocks -------------*/

export const CreateProjectBlock = (data, projectId) => {
    const requestData = {
        url: `${_urlProjects}/${projectId}/block`,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                notificationSuccess({
                    description: "Սլայդերը հաջողությամբ ստեղծվեց!"
                })
                history.push(`/dashboard/projects/edit/${projectId}`)
            })
    }
};

export const UpdateProjectBlock = (id, data, projectId) => {
    const requestData = {
        url: `${_urlProjects}/${projectId}/block/${id}`,
        token: true,
        method: "PATCH",
        data
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                notificationSuccess({
                    description: "Բաժինը հաջողությամբ փոփոխվեց!"
                })
                history.push(`/dashboard/projects/edit/${projectId}`)
            })
    }
};

export const DeleteProjectBlock = (id, projectId) => {
    const requestData = {
        url: `${_urlProjects}/${projectId}/block/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: PROJECT_CONSTS.DELETE_PROJECT_BLOCK_BY_ID,
                    payload: {id, projectId}
                });
            })
            .catch(() => {

            })
    }
};