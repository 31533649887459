// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch } from "antd";

//import styles
import '../../assets/styles/containerStyles/slider-add-edit.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import { LinkButton } from "../../components/buttons/buttons";
import { MediaFileCard } from "../../components/media/MediaFileCard";

// Import utils
import {
    getInputValues, getPatchReqData,
    toggleStateField
} from "../../utils/helperFunctions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import { CreateAboutSlider, UpdateAboutSliderById } from "../../redux/actions";
import { ACCEPT_IMAGE_TYPES } from "../../constants/acceptedTypes";

class AddEditAboutSlider extends Component {
    constructor(props){
        super(props);
        this.state = {
            fieldsData: {
                mediaMain: undefined,
                isHidden: false,
            },
            errors: {},
            isEditing: !!props?.id,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getInputValues = getInputValues.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.addEditAboutSlider = this.addEditAboutSlider.bind(this);

    }

    async componentDidMount(){
        const { isEditing } = this.state;
        if ( isEditing ) {
            const { aboutSliderList } = this.props;
            const editingData = aboutSliderList.find(slider => slider.id === this.props.id);

            const initStateData = {
                mediaMain: editingData?.mediaMain,
                isHidden: editingData?.isHidden,
            }
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }

    getMedia(mediaArray){
        const { fieldsData, errors, isEditing } = this.state;
        if ( isEditing ) {
            this.currentData?.mediaMain?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('mediaMain', mediaArray[0]?.id)
                : this.updatedDataMap.delete('mediaMain');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: mediaArray[0],
            },
            errors: {
                ...errors,
                mediaMain: false
            }
        })
    }

    deleteMedia(){
        const { fieldsData, isEditing } = this.state;
        if ( isEditing ) {
            this.currentData?.mediaMain ?
                this.updatedDataMap.set("mediaMain", null) :
                this.updatedDataMap.delete("mediaMain");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: null
            }
        })
    }


    async addEditAboutSlider(){
        await this.setState({
            requestLoading: true
        });
        const { mediaMain, isHidden, } = this.state.fieldsData;

        const errors = {};
        let result = true;

        if ( !mediaMain ) {
            errors.mediaMain = true;
            result = false;
        }
        if ( result ) {

            if ( !this.state.isEditing ) {
                const reqData = {
                    isHidden,
                    mediaMain: mediaMain.id,
                };
                this.props.CreateAboutSlider(reqData).finally(() => {
                    this.setState({ requestLoading: false });
                })
            } else {
                const reqData = {
                    isHidden,
                    mediaMain: mediaMain.id,
                };
                this.props.UpdateAboutSliderById(this.props.id, reqData)
                    .finally(() => {
                        this.setState({ requestLoading: false });
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
            })
        }
    }

    render(){
        const { fieldsData, errors, mediaModalOpen, requestLoading, isEditing} = this.state;

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Սլայդեր' : 'Փոփոխել Սլայդերը'}>
            <section className="slider-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>
                <div className="top-side">
                    <label>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData?.mediaMain && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.mediaMain}
                                   customDelete={this.deleteMedia}
                    />
                </div>}

                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditAboutSlider}/>
                </div>

            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateAboutSlider,
    UpdateAboutSliderById
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditAboutSlider)
