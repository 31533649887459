export const CRUD_ICONS = {
    POST: 'add',
    PATCH: 'refresh',
    PUT: 'refresh',
    DELETE: 'delete',
};

export const SOURCE_KEYS = {
    BUILDING: {
        title: 'Շենքեր',
        key: 'BUILDING',
    },
    FILE: {
        title: 'Ֆայլեր',
        key: 'FILE',
    },
    FOLDER:{
        title: 'Թղթապանակ',
        key: 'FOLDER',
    },
    FLOOR:{
        title: 'Հարկեր',
        key: 'FLOOR',
    },
    AREA:{
        title: 'Տարածք',
        key: 'AREA',
    },
    PROJECT:{
        title: 'Նախագծեր',
        key: 'PROJECT',
    },
    RESERVATION:{
        title: 'Ամրագրումներ',
        key: 'RESERVATION',
    },
    TRANSLATION:{
        title: 'Թարգմանություններ',
        key: 'TRANSLATION',
    },
};