// import React from "react";
import {_urlLogs, request} from "../api";


//import utils
import {LOG_CONSTS} from "../constants";


export const GetLogs = ({
                            offset = 0,
                            limit = 20,
                            reset = true,
                            sourceKeys = null,
                            user = null,
                        } = {}) => {
    let url = `${_urlLogs}?offset=${offset}&limit=${limit}`;
            sourceKeys && (url += `&sourceKeys=${sourceKeys}`);
            user && (url += `&user=${user}`);
    const requestData = {
        url,
        method: "GET",
        languageFlag: true,
        scope: true,
        token: true
    };

    return dispatch => {
        // dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: LOG_CONSTS.GET_LOGS,
                    payload: {
                        data,
                        reset,
                        sourceKeys,
                        user,
                        hasMore: data?.length === limit
                    }
                });
                return data;
            })
            .catch(err => {
            }).finally(() => {
                // dispatch({type: UTIL_CONSTS.END_LOADING});
            })
    };
};


