// Import packages
import React, {useEffect, useState} from "react";
import InfiniteScroll from "react-infinite-scroller";
import moment from "moment";
import {connect} from "react-redux";

// Import styles
import "./side-menu.scss";
import {ReactComponent as CloseIcon} from "../../../assets/images/ic_close.svg";

// Import utils
import {mapStateToProps} from "../../../redux/mapStateToProps";
import {GetLogs} from "../../../redux/actions";
import {MaterialIcon} from "../../utils/Icon";
import {CRUD_ICONS} from "./constants";
import {InputGroup} from "../inputGroup";

function SideMenu(props) {
    const {showMenu, toggleMenu, newLogList, newLogHasMore, moderatorsList} = props;

    const [fields, setFields] = useState({
        user: undefined
    });

    //Options
    const admin = {
        firstName: "ADMIN",
        lastName: "",
        id: "600fef79c9c2ec294126809c"
    }
    const moderatorsOptions = Array.isArray(moderatorsList) ? [admin, ...moderatorsList]?.map(item => ({
        name: item?.firstName + ' ' + item?.lastName,
        id: item?.id,
    })) : [];

    useEffect(() => {
        // props.GetLogs()
        window.addEventListener('click', () => toggleMenu(false));
        return () => {
            window.removeEventListener('click', () => toggleMenu(false))
        }
    })

    useEffect(() => {
        // props.GetLogs()
        setFields({user: undefined});
    },[showMenu]);

    function loadMoreItems() {
        newLogHasMore && props.GetLogs({
            reset: false,
            offset: newLogList.length,
        });
    }

    function getInputValues({name, value}) {
        setFields({
            ...fields,
            [name]: value,
        })
        props.GetLogs({user: value})
    }

    return <div className={`side-menu ${showMenu ? 'show' : 'hide'}`} onClick={event => event.stopPropagation()}>
        <div className="header">
            <span>Menu</span>
            <CloseIcon title={''} className={'close-icon'} onClick={() => toggleMenu(false)}/>
        </div>
        <div className="side-menu__sort">
            <InputGroup
                value={fields.user}
                inputType="selectCustom"
                name="user"
                withClear
                placeholder={'Օգտատեր'}
                onChange={getInputValues}
                options={moderatorsOptions}
            />
        </div>
        <div className={'new-logs'}>
            <InfiniteScroll
                hasMore={newLogHasMore}
                loadMore={loadMoreItems}
                pageStart={0}
                isReverse={true}
                useWindow={false}
                initialLoad={false}>
                {
                    newLogList?.map((item) => {
                        return <div className={`log-item`} key={item.id}>
                            <span className={`log-icon ${CRUD_ICONS?.[item?.requestMethod]}`}>
                            <MaterialIcon icon={CRUD_ICONS?.[item?.requestMethod]}/>
                            </span>

                            <div className={'log-content'}>
                                <bold>{item?.userFullName}</bold>
                                {item?.actionName}{' '}
                                {item?.sourceName}{' '}
                                <span className={'log-content__file-name'}>{item?.itemName}</span>
                                <div className={'log-time'}>
                                    {moment(item?.createdAt).format('DD-MM-YYYY HH:mm')}
                                </div>
                            </div>
                        </div>
                    })
                }
            </InfiniteScroll>
        </div>
    </div>
}

const mapDispatchToProps = {
    GetLogs
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);