import { SUBSCRIBERS_CONSTS } from "../constants";

export const initialState = {
    subscribers: {
        itemsList: [],
        hasMore: false,
    },
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case SUBSCRIBERS_CONSTS.GET_SUBSCRIBERS:
            return {
                ...state,
                subscribers: {
                    itemsList: action.payload.reset ? action.payload.data : [...state.subscribers.itemsList, ...action.payload.data],
                    hasMore: action.payload.hasMore,
                }
            };

        case SUBSCRIBERS_CONSTS.DELETE_SUBSCRIBER_BY_ID:
            return {
                ...state,
                subscribers: {
                    ...state.subscribers,
                    itemsList: state.subscribers.itemsList.filter(item => item.id !== action.payload)
                }
            };

        default:
            return state;
    }
}
