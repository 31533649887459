import { RESERVATIONS_CONSTS } from "../constants";

export const initialState = {
    houseReservations: {
        itemsList: [],
        hasMore: false,
        isHouse: true,
    },
    parkingReservations: {
        itemsList: [],
        hasMore: false,
        isHouse: true,
    },
    callStatement: {
        itemsList: [],
        hasMore: false,
        isHouse: true,
    },
    visitStatement: {
        itemsList: [],
        hasMore: false,
        isHouse: true,
    },
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case RESERVATIONS_CONSTS.GET_RESERVATIONS:
            return {
                ...state,
                [action.payload.key]: {
                    itemsList: action.payload.reset ? action.payload.data : [...state[action.payload.key]?.itemsList, ...action.payload.data],
                    hasMore: action.payload.hasMore,
                    isHouse: action.payload.isHouse,
                }
            };

        case RESERVATIONS_CONSTS.DELETE_RESERVATION_BY_ID:
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    itemsList: state[action.payload.key]?.itemsList.filter(item => item.id !== action.payload?.id)
                }
            };
        case RESERVATIONS_CONSTS.GET_CALL_STATEMENT:
            return {
                ...state,
                visitStatement: {
                    itemsList: action.payload.reset ? action.payload.data : [...state[action.payload.type]?.itemsList, ...action.payload.data],
                    hasMore: action.payload.hasMore,
                    isHouse: action.payload.isHouse,
                },
                callStatement: {
                    itemsList: action.payload.reset ? action.payload.data : [...state[action.payload.type]?.itemsList, ...action.payload.data],
                    hasMore: action.payload.hasMore,
                    isHouse: action.payload.isHouse,
                }
            };

        case RESERVATIONS_CONSTS.DELETE_CALL_STATEMENT_BY_ID:
            return {
                ...state,
                callStatement: {
                    itemsList: state.callStatement?.itemsList.filter(item => item.id !== action.payload)
                },
                visitStatement: {
                    itemsList: state.visitStatement?.itemsList.filter(item => item.id !== action.payload)
                }
            };
        default:
            return state;
    }
}
