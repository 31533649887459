// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

// Import components
import ItemsList from "../../components/ItemsList";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import moment from "moment";

// Import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import {
    GetSubscribers,
    DeleteSubscriberById
} from "../../redux/actions";
import { deleteItem } from "../../utils/helperFunctions";


class Subscribers extends Component {
    constructor() {
        super();
        this.loadMoreItems = this.loadMoreItems.bind(this);
    }

    componentDidMount(){
        this.props.GetSubscribers();
    }

    loadMoreItems() {
        const {subscribers} = this.props;
        subscribers?.hasMore && this.props.GetSubscribers({
            reset: false,
            offset: subscribers?.itemsList?.length,
        });
    };

    render(){
        let { subscribers,  requestLoading } = this.props;
        let itemListHeaderInfo = {
            generalInfo: ["Էլ․ հասցե", "Գր․ ամսաթիվ"],
            largeItems: [0],
            actions: ['delete'],
        };
        let itemListInfo = subscribers?.itemsList?.map((item) => {
            const { id, email, createdAt} = item;
            return {
                id,
                values: [
                    email ?? '-',
                    createdAt ? moment(createdAt).format('DD/MM/YYYY HH:mm'): '-'
                ],
                deleteCb: deleteItem.bind(this, this.props.DeleteSubscriberById, 'բաժանորդագրվածին', id)
            }
        });
        return <PageWrapper pageTitle={'Բաժանորդագրվածներ'}>
            <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                       itemListInfo={itemListInfo}
                       hasMore={subscribers?.hasMore}
                       useWindow={true}
                       loadMoreItems={this.loadMoreItems}
                       infiniteScroll={true}
                       loading={requestLoading}/>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetSubscribers,
    DeleteSubscriberById
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscribers);

