import { BUILDING_CONSTS, AUTH_CONSTS } from "../constants";

export const initialState = {
    buildings: [],
    areas: [],
    floors: [],
    buildingFloors: [],
    floorById: null,
    houseById: null,
    parkingById: null,
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case BUILDING_CONSTS.GET_BUILDINGS:
            return {
                ...state,
                buildings: action.payload,
            };

        case BUILDING_CONSTS.SET_FLOORS:
            return {
                ...state,
                floors: action.payload,
            };
        case BUILDING_CONSTS.SET_FLOOR_BY_ID:
            return {
                ...state,
                floorById: action.payload,
            };
        case BUILDING_CONSTS.GET_FLOOR_BY_ID:
            return {
                ...state,
                floorById: action.payload,
            };
        case BUILDING_CONSTS.GET_FLOOR_BY_BUILDING_ID:
            return {
                ...state,
                buildingFloors: action.payload,
            };
        //AREAS
        case BUILDING_CONSTS.GET_AREAS:
            return {
                ...state,
                areas: action.payload,
            };
        case BUILDING_CONSTS.GET_AREA_BY_ID:
            return {
                ...state,
                houseById: action.payload,
            };
        case BUILDING_CONSTS.DELETE_AREA_BY_ID:
            return {
                ...state,
                areas: state.areas ? state.areas.filter(h => h.id !== action.payload) : []
            };

        case BUILDING_CONSTS.CLEAR_AREAS_DATA:
            return {
                ...state,
                areas: [],
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState
        default:
            return state;
    }
}
