import {BANKS_CONSTS} from "../constants";

export const initialState = {
    banksList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case BANKS_CONSTS.GET_BANKS:
            return {
                ...state,
                banksList: action.payload || [],
            };
        case BANKS_CONSTS.CREATE_BANK:
            return {
                ...state,
                banksList: [action.payload].concat(state.banksList)
            };
        case BANKS_CONSTS.UPDATE_BANK:
            return {
                ...state,
                banksList: state.banksList.map(item => item.id === action.payload.id
                    ? action.payload : item)
            };
        case BANKS_CONSTS.DELETE_BANK_BY_ID:
            return {
                ...state,
                banksList: state.banksList.filter(item => item.id !== action.payload)
            };
        default:
            return state;
    }
}
