// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

//import styles
import '../../assets/styles/containerStyles/about.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";
import { LanguageTabs } from "../../components/uiElements/Tabs";
// Import utils
import {
    changeStateField,
    getInputValues, getPatchReqData,
    getTranslatableInputValues, getTranslationData,
    initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import { GetAboutData, UpdateAboutData } from "../../redux/actions";
import { validateTranslations } from "../../utils/validateTranslations";
import { ACCEPT_IMAGE_TYPES } from "../../constants/acceptedTypes";
import { generateFileMediaUrl } from "../../utils/generateMemberMediaUrl";
import ItemsList from "../../components/ItemsList";
import {Editor} from "../../components/uiElements/Editor";

class About extends Component {
    constructor(props){
        super(props);
        this.translationsFields = [
            'pageName',
            'title',
            'description',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                backgroundImage: undefined,
                projectsCount: '',
                commercialsCount: '',
                apartmentsCount: '',
                workersCount: '',
            },
            errors: {},
            errorsTabs: [],
            isEditing: true,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.setAboutData = this.setAboutData.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.updateAbout = this.updateAbout.bind(this);

    }

    async componentDidMount(){
        await this.props.GetAboutData()
        const { about } = this.props;
        this.setAboutData(about)
    }

    setAboutData(about){
        const { fieldsData } = this.state;
        const translationsData = {};

        if ( about ) {
            Object.keys(fieldsData.translations).forEach(key => {
                const trItem = about.translations.find(item => item.language === key);
                translationsData[key] = {
                    ...fieldsData.translations[key],
                    pageName: trItem?.pageName || '',
                    title: trItem?.title || '',
                    description: trItem?.description || '',
                };
            });
        }
        const initStateData = {
            translations: translationsData,
            backgroundImage: about?.backgroundImage,
            projectsCount: about?.projectsCount || '',
            commercialsCount: about?.commercialsCount || '',
            apartmentsCount: about?.apartmentsCount || '',
            workersCount: about?.workersCount || '',
        }
        this.currentData = JSON.parse(JSON.stringify(initStateData));
        this.setState({
            fieldsData: initStateData,
            errors: {},
            errorsTabs: [],
            requestLoading: false,
        });
    }

    getMedia(mediaArray){
        const { fieldsData, errors, isEditing } = this.state;
        if ( isEditing ) {
            this.currentData?.backgroundImage?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('backgroundImage', mediaArray[0]?.id)
                : this.updatedDataMap.delete('backgroundImage');
        }
        console.log("mediaArr", mediaArray)
        this.setState({
            fieldsData: {
                ...fieldsData,
                backgroundImage: mediaArray[0],
            },
            errors: {
                ...errors,
                backgroundImage: false
            }
        })
    }

    async updateAbout(){
        await this.setState({
            requestLoading: true
        });
        const { translations, backgroundImage } = this.state.fieldsData;
        const translationRequiredFields = ['pageName', 'title', 'description']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = { ...validationTr.errors };

        if ( !backgroundImage ) {
            errors.backgroundImage = true;
            result = false;
        }
        if ( result ) {
            const trData = getTranslationData(translations);
            const reqData = this.getPatchReqData(trData);
            this.props.UpdateAboutData(reqData).then((updatedData) => {
                this.updatedDataMap.clear();
                this.setAboutData(updatedData)
            }).catch(() => {
            }).finally(() => {
                this.setState({ requestLoading: false });
            });

        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    getListInfo(data){
        let { mainLanguage } = this.props;
        return data?.map((item) => {
            const { slug, translations } = item;
            const mainTranslations = translations?.find(item => item.language === mainLanguage);
            const { title } = mainTranslations || {};
            return {
                id: slug,
                values: [
                    title ?? '-',
                ],
            }
        });
    }

    render(){
        const { about } = this.props;
        const { fieldsData, errors, languageTab, mediaModalOpen, requestLoading, isEditing, errorsTabs } = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};
        const itemListHeaderInfo = {
            generalInfo: ["Անվանում"],
            largeItems: [0],
            actions: ["edit"],
        };
        const featuresListInfo = this.getListInfo(about?.features);
        const blocksListInfo = this.getListInfo(about?.blocks);
        return <PageWrapper pageTitle={'Մեր Մասին'}>
            <section className="about">
                <div className="top-side">
                    <label>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.backgroundImage ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}/>
                </div>
                {fieldsData?.backgroundImage && <div className={'background-image'}>
                    <img src={generateFileMediaUrl(fieldsData?.backgroundImage?.path)} alt={''}/>
                </div>}
                <div className="info-wrapper">
                    <div className={'numbers-part'}>
                        <div className="left-part">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={"ՆԱԽԱԳԾԵՐ"}
                                        placeholder="ՆԱԽԱԳԾԵՐ"
                                        name="projectsCount"
                                        integerNumber={true}
                                        value={fieldsData?.projectsCount}
                                        required={true}
                                        initValue={this.currentData?.projectsCount}
                                        error={errors?.projectsCount}
                                        onChange={this.getInputValues}/>
                            <InputGroup inputType="input"
                                        type="text"
                                        label={"ԿՈՄԵՐՑԻՈՆ ՏԱՐԱԾՔՆԵՐ"}
                                        placeholder="ԿՈՄԵՐՑԻՈՆ ՏԱՐԱԾՔՆԵՐ"
                                        name="commercialsCount"
                                        integerNumber={true}
                                        value={fieldsData?.commercialsCount}
                                        required={true}
                                        initValue={this.currentData?.commercialsCount}
                                        error={errors?.commercialsCount}
                                        onChange={this.getInputValues}/>

                            {/*<InputGroup inputType="input"*/}
                            {/*            type="text"*/}
                            {/*            label={"ԲՆԱԿԱՐԱՆՆԵՐ"}*/}
                            {/*            placeholder="ԲՆԱԿԱՐԱՆՆԵՐ"*/}
                            {/*            name="apartmentsCount"*/}
                            {/*            integerNumber={true}*/}
                            {/*            value={fieldsData?.apartmentsCount}*/}
                            {/*            required={true}*/}
                            {/*            initValue={this.currentData?.apartmentsCount}*/}
                            {/*            error={errors?.apartmentsCount}*/}
                            {/*            onChange={this.getInputValues}/>*/}
                        </div>
                        <div className="right-part">

                            <InputGroup inputType="input"
                                        type="text"
                                        label={"ԱՇԽԱՏԱՆՔԻ ՏԱՐԻՆԵՐ"}
                                        placeholder="ԱՇԽԱՏԱՆՔԻ ՏԱՐԻՆԵՐ"
                                        name="workersCount"
                                        integerNumber={true}
                                        value={fieldsData?.workersCount}
                                        required={true}
                                        initValue={this.currentData?.workersCount}
                                        error={errors?.workersCount}
                                        onChange={this.getInputValues}/>
                        </div>
                    </div>
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={errorsTabs}
                                  activeTab={languageTab}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Էջի Անվանում"
                                placeholder="Էջի Անվանում"
                                name="pageName"
                                value={currentData.pageName}
                                initValue={initData.pageName}
                                error={errors['pageName' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Վերնագիր"
                                placeholder="Վերնագիր"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    {/*<InputGroup inputType="input"*/}
                    {/*            type="text"*/}
                    {/*            label="Ենթավերնագիր"*/}
                    {/*            placeholder="Ենթավերնագիր"*/}
                    {/*            name="description"*/}
                    {/*            value={currentData.description}*/}
                    {/*            initValue={initData.description}*/}
                    {/*            error={errors['description' + languageTab]}*/}
                    {/*            maxLength={1000}*/}
                    {/*            required={true}*/}
                    {/*            onChange={this.getTranslationValues}/>*/}
                    <div className="editor-wrapper">
                        <label>Ենթավերնագիր <span className={'required-badge'}>*</span></label>
                        <Editor value={currentData?.description || ''}
                                name={'description'}
                                initValue={initData?.description}
                                error={errors['description' + languageTab]}
                                onChange={this.getTranslationValues}/>
                    </div>
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.updateAbout}/>
                </div>
                <label className={'list-label'}>Հատկություններ</label>
                <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                           itemListInfo={featuresListInfo}
                           loading={requestLoading}
                           editLink={"/about/feature"}/>
                <label className={'list-label'}>Բաժիններ</label>
                <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                           itemListInfo={blocksListInfo}
                           loading={requestLoading}
                           editLink={"/about/block"}/>


            </section>

            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetAboutData,
    UpdateAboutData
};

export default connect(mapStateToProps, mapDispatchToProps)(About)
