import {history} from "../../configs/history";
import {request, _urlBuildings, _urlFloors, _urlAreas} from "../api";
import {BUILDING_CONSTS, UTIL_CONSTS} from "../constants";
import {notificationSuccess} from "../../components/uiElements/Notifications";

export const GetBuildings = (projectId) => {
    let url = `${_urlBuildings}`;
    projectId && (url += `?project=${projectId}`);
    const requestData = {
        url: url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res.data;
                data && await dispatch({
                    type: BUILDING_CONSTS.GET_BUILDINGS,
                    payload: data,
                });
            })
    }
};


export const UpdateBuilding = (data, id) => {
    const requestData = {
        url: `${_urlBuildings}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING,
        });
        return request(requestData)
            .then(res => {
                notificationSuccess({
                    description: "Շենքը հաջողությամբ փոփոխվեց!"
                })
                history.push("/dashboard")
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING,
                });
            })
    }
};

//============================ Floors - start ==========================

export const setFloors = (data) => {
    return dispatch => {
        dispatch({
            type: BUILDING_CONSTS.SET_FLOORS,
            payload: data
        });
    }
};

export const GetFloorById = (id) => {
    const requestData = {
        url: `${_urlFloors}/${id}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: BUILDING_CONSTS.GET_FLOOR_BY_ID,
                    payload: data
                });
                return data
            })
    }
};

export const GetFloorsByBuildingId = (id) => {
    const requestData = {
        url: `${_urlFloors}/building/${id}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: BUILDING_CONSTS.GET_FLOOR_BY_BUILDING_ID,
                    payload: data
                });
                return data
            })
    }
};
export const UpdateFloorById = (id, data) => {

    const requestData = {
        url: `${_urlFloors}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING,
        });
        return request(requestData)
            .then(res => {
                const response = res?.data
                dispatch({
                    type: BUILDING_CONSTS.SET_FLOOR_BY_ID,
                    payload: response
                });
                notificationSuccess({
                    description: "Հարկը հաջողությամբ փոփոխվեց!"
                })
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING,
                });
            })
    }
};

//============================ Floors - end ===========================
//======================================================================
//============================ Areas - start ==========================

export const GetAreas = (floorId = null) => {
    let url = `${_urlAreas}`;
    floorId && (url += `?floorId=${floorId}`);
    const requestData = {
        url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res.data;
                data && await dispatch({
                    type: BUILDING_CONSTS.GET_AREAS,
                    payload: data,
                });
                return res
            })
    }
};

export const GetAreaById = (id) => {
    const requestData = {
        url: `${_urlAreas}/${id}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: BUILDING_CONSTS.GET_AREA_BY_ID,
                    payload: data
                });
            })
    }
};

export const CreateArea = (data) => {
    let url = `${_urlAreas}`;
    const requestData = {
        url,
        token: true,
        method: "POST",
        data
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                //const data = res.data;
                notificationSuccess({
                    description: "Բնակարանը հաջողությամբ ստեղծվեց!"
                })
                history.push(`/dashboard/floor-details/${data?.floor}`)
            })
    }
};

export const UpdateArea = (id, data, floorId) => {
    let url = `${_urlAreas}/${id}`;
    const requestData = {
        url,
        token: true,
        method: "PATCH",
        data
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                //const data = res.data;
                notificationSuccess({
                    description: "Բնակարանը հաջողությամբ փոփոխվեց!"
                })
                history.push(`/dashboard/floor-details/${floorId}`)
            })
    }
};

export const UpdateAreasPositions = items => {
    const requestData = {
        url: _urlAreas,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                notificationSuccess({
                    description: "Բնակարանները հաջողությամբ փոփոխվեցին!"
                })
            })
    }
};

export const DeleteArea = (id,) => {
    let url = `${_urlAreas}/${id}`;
    const requestData = {
        url,
        token: true,
        method: "DELETE",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                dispatch({
                    type: BUILDING_CONSTS.DELETE_AREA_BY_ID,
                    payload: id
                });
            })
    }
};

export const clearAreasData = () => {
    return dispatch => {
        dispatch({
            type: BUILDING_CONSTS.CLEAR_AREAS_DATA,
        });
    }
};


//============================ Areas - end ==========================