// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import assets
import "../../assets/styles/containerStyles/reservations-update.scss"

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {UpdateReservationStatus} from "../../redux/actions";
import {history} from "../../configs/history";
import moment from "moment";
import {LinkButton} from "../../components/buttons/buttons";
import {Link} from "react-router-dom";
import {RESERVATION_STATUS_TYPES} from "../../constants/constType";


class UpdateReservation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reservation: {},
            requestLoading: false,
        };
        this.updateReservation = this.updateReservation.bind(this);
    }

    async componentDidMount() {
        const {houseReservations, parkingReservations, match} = this.props;
        const {id} = match?.params || {}
        if (!id) {
            history.push('/reservations');
        }
        const singleReserve = [...houseReservations?.itemsList, ...parkingReservations?.itemsList]?.find(item => item?.id === id)
        console.log(singleReserve);

        this.setState({
            reservation: singleReserve
        })
    }


    updateReservation(status) {
        const {id} = this.props.match?.params
        this.props.UpdateReservationStatus(id, status)
    }

    render() {
        const {reservation} = this.state
        const place = reservation?.house?.id ? reservation?.house : reservation?.parking
        const floorById = {
            ...place?.floor,
            building: {
                id: place?.floor?.building,
                slug: place?.building?.slug
            }
        }
        return <PageWrapper pageTitle={'Թարմացնել Ամրագրումը'}>
            <section className="general-add-edit reservations-wrapper">
                <div className={'building-top-side'}>
                    <div className={'building-logo-wrapper'}>
                        <span>{place?.building?.slug}</span>
                    </div>
                    <div className={'top-side-info-wrapper'}>
                        <div className={'info info-bold'}>
                            <span>Մասնաշենք</span>
                        </div>
                        <div className={'info info-grey'}>
                            <span> {place?.building?.address}</span>
                        </div>
                    </div>
                </div>
                <div className={'building-bottom-side'}>
                    <div className={'info'}>
                        <span>Բնակարան։ &nbsp;</span>
                        <span>
                            <Link to={{
                                pathname: `/dashboard/house-details/${place?.id}`,
                                state: {
                                    floor: floorById,
                                    id: place?.id
                                }
                            }}>N {place?.houseNumber ? place?.houseNumber : place?.parkingNumber}</Link></span>
                    </div>
                    <div className={'info'}>
                        <span>Ամրագրման ամսաթիվ։ &nbsp;</span>
                        <span>{moment(reservation?.createdAt).format('DD/MM/YYYY')}</span>
                    </div>
                    <div className={'info'}>
                        <span>Ամրագրող։ &nbsp;</span>
                        <span>{reservation?.fullName}</span>
                    </div>
                    <div className={'info'}>
                        <span>Հեռախոսահամար։ &nbsp;</span>
                        <span>{reservation?.phoneNumber}</span>
                    </div>
                    <div className={'info'}>
                        <span>Էլ․ հասցե &nbsp;</span>
                        <span>{reservation?.email}</span>
                    </div>
                    {reservation?.status === RESERVATION_STATUS_TYPES?.PENDING.key ?
                        <div className="status-wrapper">
                            <LinkButton title={"Մերժել"} className="reject-btn bg-red"
                                        cb={() => this.updateReservation("REJECTED")}/>
                            <LinkButton title={"Հաստատել"} className="accept-btn bg-green"
                                        cb={() => this.updateReservation("ACCEPTED")}/>
                        </div>
                        :
                        <div className={'info'}>
                            <span>Կարգավիճակ։ &nbsp;</span>
                            <span style={{color: RESERVATION_STATUS_TYPES[reservation?.status]?.color}}>
                                {RESERVATION_STATUS_TYPES[reservation?.status]?.name}
                            </span>
                        </div>
                    }
                </div>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    UpdateReservationStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateReservation)
