// Import packages
import React from "react";
import {Route, Switch, Router, Redirect} from "react-router-dom";

// Import utils
import {history} from "../configs/history";
import {ProtectedRoute} from "./protectedRoute";

// Import pages
import AdminLayout from '../containers/AdminLayout';
import Login from '../containers/Login';

import Dashboard from '../containers/Dashboard';
import UpdateProject from '../containers/project/UpdateProject'
import Buildings from '../containers/building/Buildings';
import Floors from '../containers/building/Floors';
import FloorDetails from '../containers/building/FloorDetails';
import AddEditHouse from "../containers/building/AddEditArea";

import NotFound from '../containers/NotFound';
import Contact from '../containers/Contact';

import Moderators from '../containers/Moderators/Moderators';
import AddEditModerator from "../containers/Moderators/AddEditModerator";

import Media from "../containers/media/Media";
import EditFile from "../containers/media/EditFile";

import Languages from "../containers/languages/Languages";
import EditLanguage from "../containers/languages/EditLanguage";

// import Menu from "../containers/menu/Menu";
// import AddEditMenu from "../containers/menu/AddEditMenu";

import Slider from "../containers/slider/Slider";
import AddEditSlider from "../containers/slider/AddEditSlider";

import RoomTypes from "../containers/roomTypes/RoomTypes";
import AddEditRoomType from "../containers/roomTypes/AddEditRoomType";

import Badges from "../containers/badges/Badges";
import AddEditBadge from "../containers/badges/AddEditBadges";

import Filters from "../containers/filters/Filters";
import AddFilter from "../containers/filters/AddFilter";
import EditFilter from "../containers/filters/EditFilter";

import About from "../containers/about/About";
import EditFeatureOrBlock from "../containers/about/EditFeatureOrBlock";
import AboutSlider from "../containers/about/AboutSlider";
import AddEditAboutSlider from "../containers/about/AddEditAboutSlider";

import Subscribers from "../containers/subscribers/Subscribers";
import SendEmail from "../containers/subscribers/SendEmail";

import Reservations from "../containers/reservations/Reservations";

import StaticTexts from "../containers/staticTexts/StaticTexts";
import CustomPages from "../containers/customPages/CustomPages";
import AddEditCustomPages from "../containers/customPages/AddEditCustomPages";
import Banks from "../containers/banks/Banks";
import AddEditBank from "../containers/banks/AddEditBank";
import ReservationsUpdate from "../containers/reservations/ReservationsUpdate";
import AddEditBlock from "../containers/project/AddEditBlock";
import Statement from "../containers/statement/Statement";


export default () => {

    return <Router history={history}>
        <Switch>
            <Redirect exact from="/" to="/login"/>
            <Route exact path="/login" component={Login}/>
            <AdminLayout>
                <Switch>
                    <Route exact path="/dashboard" component={Dashboard}/>
                    <ProtectedRoute exact path="/dashboard/projects/edit/:id" component={UpdateProject}/>
                    <ProtectedRoute exact path="/dashboard/projects/:project/add-block" component={AddEditBlock}/>
                    <ProtectedRoute exact path="/dashboard/projects/:project/edit-block/:id" component={AddEditBlock}/>
                    <ProtectedRoute exact path="/dashboard/projects/:id" component={Buildings}/>
                    <ProtectedRoute exact path="/dashboard/floors/:id" component={Floors}/>
                    <ProtectedRoute exact path="/dashboard/floor-details/:id" component={FloorDetails}/>
                    <ProtectedRoute exact path="/dashboard/house-details/:id" component={AddEditHouse}/>
                    <ProtectedRoute exact path="/dashboard/create-house" component={AddEditHouse}/>

                    <ProtectedRoute exact path="/moderators" component={Moderators}/>
                    <ProtectedRoute exact path="/moderators/add" component={AddEditModerator}/>
                    <ProtectedRoute exact path="/moderators/edit/:id" component={AddEditModerator}/>

                    <ProtectedRoute exact path="/badges" component={Badges}/>
                    {/*<ProtectedRoute exact path="/badges/add" component={AddEditBadge}/>*/}
                    <ProtectedRoute exact path="/badges/edit/:id" component={AddEditBadge}/>

                    <ProtectedRoute exact path="/media" component={Media}/>
                    <ProtectedRoute exact path="/media/edit/:id" component={EditFile}/>

                    <ProtectedRoute exact path="/roomTypes" component={RoomTypes}/>
                    <ProtectedRoute exact path="/roomTypes/add" component={AddEditRoomType}/>
                    <ProtectedRoute exact path="/roomTypes/edit/:id" component={AddEditRoomType}/>

                    <ProtectedRoute exact path="/banks" component={Banks}/>
                    <ProtectedRoute exact path="/banks/add" component={AddEditBank}/>
                    <ProtectedRoute exact path="/banks/edit/:id" component={AddEditBank}/>

                    <ProtectedRoute exact path="/slider" component={Slider}/>
                    <ProtectedRoute exact path="/slider/add" component={AddEditSlider}/>
                    <ProtectedRoute exact path="/slider/edit/:id" component={AddEditSlider}/>

                    <ProtectedRoute exact path="/about" component={About}/>
                    <ProtectedRoute exact path="/about/:type/:id" component={EditFeatureOrBlock}/>

                    <ProtectedRoute exact path="/about-slider" component={AboutSlider}/>
                    <ProtectedRoute exact path="/about-slider/add" component={AddEditAboutSlider}/>
                    <ProtectedRoute exact path="/about-slider/edit/:id" component={AddEditAboutSlider}/>

                    <ProtectedRoute exact path="/filters" component={Filters}/>
                    <ProtectedRoute exact path="/filters/add" component={AddFilter}/>
                    <ProtectedRoute exact path="/filters/edit/:id" component={EditFilter}/>

                    <ProtectedRoute exact path="/customPages" component={CustomPages}/>
                    <ProtectedRoute exact path="/customPages/add" component={AddEditCustomPages}/>
                    <ProtectedRoute exact path="/customPages/edit/:id" component={AddEditCustomPages}/>

                    <ProtectedRoute exact path="/languages" component={Languages}/>
                    <ProtectedRoute exact path="/languages/edit/:id" component={EditLanguage}/>

                    <ProtectedRoute exact path="/subscribers" component={Subscribers}/>
                    <ProtectedRoute exact path="/subscribers/send" component={SendEmail}/>

                    <ProtectedRoute exact path="/reservations" component={Reservations}/>
                    <ProtectedRoute exact path="/reservations/update/:id" component={ReservationsUpdate}/>
                    <ProtectedRoute exact path="/statement" component={Statement}/>

                    <ProtectedRoute exact path="/contact" component={Contact}/>
                    <ProtectedRoute exact path="/staticTexts" component={StaticTexts}/>

                    <Route exact path="/404" component={NotFound}/>
                    <Route component={NotFound}/>
                </Switch>
            </AdminLayout>
        </Switch>
    </Router>
}
