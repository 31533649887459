import {request, _urlBanks} from "../api";
import {BANKS_CONSTS} from "../constants";
import {history} from "../../configs/history";
import {notificationSuccess} from "../../components/uiElements/Notifications";

export const GetBanks = () => {
    const requestData = {
        url: _urlBanks,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: BANKS_CONSTS.GET_BANKS,
                    payload: data
                });
            })
    }
};


export const DeleteBankById = id => {
    const requestData = {
        url: `${_urlBanks}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: BANKS_CONSTS.DELETE_BANK_BY_ID,
                    payload: id
                });
            })
    }
};

export const CreateBank = data => {
    const requestData = {
        url: _urlBanks,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: BANKS_CONSTS.CREATE_BANK,
                    payload: res?.data
                });
                notificationSuccess({
                    description: "Բանկը հաջողությամբ ստեղծվեց!"
                })
                history.push('/banks')
            })
    }
};

export const UpdateBankById = (id, data) => {
    const requestData = {
        url: `${_urlBanks}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(async res => {
                dispatch({
                    type: BANKS_CONSTS.UPDATE_BANK,
                    payload: res?.data
                });
                notificationSuccess({
                    description: "Սենյակի տեսակը հաջողությամբ փոփոխվեց!"
                })
                history.push('/banks')
            })
    }
};

export const UpdateBanksPositions = items => {
    const requestData = {
        url: _urlBanks,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                notificationSuccess({
                    description: "Բանկը հաջողությամբ թարմացվեց!"
                })
            })
    }
};
