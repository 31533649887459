// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {history} from "../configs/history";
import {withRouter} from 'react-router-dom';

//Import styles
import "../assets/styles/containerStyles/adminLayout.scss";

//Import components
import {LeftSideMenu} from "../components/navbars/LeftSideMenu";
import {MainHeader} from "../components/headerViews/mainHeader";
import {Footer} from "./Footer";
import {Icon} from "antd";

// Import utils
import {mapStateToProps} from "../redux/mapStateToProps";
import {
    ToggleLeftSideMenu,
    TokenValidation,
    GetLanguages,
    GetMedia,
    GetRoomTypes,
    GetCustomPages,
    GetBadges,
    GetStaticTexts,
    GetModerators
} from "../redux/actions";
import {LogOut} from "../redux/actions";


class AdminLayout extends Component {
    state = {
        isMounted: false,
    };

    async componentDidMount() {
        let redirectLoginPage = false;
        const {location} = this.props;
        if (!this.props.isLoggedIn) {
            redirectLoginPage = true;
        } else {
            await this.props.TokenValidation().then(async () => {
                await this.props.GetLanguages();
            }).catch(() => {
                redirectLoginPage = true;
            });
        }
        if (redirectLoginPage) {
            this.props.LogOut();
        } else {
            this.props.GetStaticTexts();
            this.props.GetMedia();
            this.props.GetRoomTypes();
            this.props.GetCustomPages();
            this.props.GetBadges();
            if (location && location.pathname.includes('moderators') && !this.props.isAdmin) {
                history.push('/404');
            }
            this.props.isAdmin && this.props.GetModerators();

            this.setState({
                isMounted: true,
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {location} = this.props;
        if (location?.pathname !== prevProps?.location?.pathname) {
            window.scrollTo(0, 0);
        }
        if (location && location.pathname.includes('moderators') && !this.props.isAdmin) {
            history.push('/404');
        }
    }

    render() {
        const {isMounted} = this.state;
        const {
            children, leftSideMenuOpen, ToggleLeftSideMenu, LogOut, isAdmin, location,
            accessPages, user, } = this.props;
        const spinIcon = <Icon type="loading" style={{fontSize: 54, color: "#1F3A62"}} spin/>;

        return isMounted ? <div className={`admin-layout ${leftSideMenuOpen ? "menu-open" : ""}`}>
                <MainHeader toggleMenu={ToggleLeftSideMenu}
                            leftSideMenuOpen={leftSideMenuOpen}
                            isAdmin={isAdmin}
                            user={user}
                            LogOut={LogOut}/>
                <LeftSideMenu leftSideMenuOpen={leftSideMenuOpen}
                              isAdmin={isAdmin}
                              accessPages={accessPages}
                              path={location && location.pathname}/>

                {children}
                <Footer leftSideMenuOpen={leftSideMenuOpen}/>
            </div> :
            <div className={'loading'}>
                {spinIcon}
            </div>
    }
}

const mapDispatchToProps = {
    ToggleLeftSideMenu,
    LogOut,
    GetLanguages,
    TokenValidation,
    GetMedia,
    GetRoomTypes,
    GetCustomPages,
    GetBadges,
    GetStaticTexts,
    GetModerators
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminLayout));
