// Import packages
import React, {Component} from "react";

// Import Styles
import '../assets/styles/components/items-list.scss';
import noDataImage from '../assets/images/ic_empty.svg';

// Import components
import InfiniteScroll from 'react-infinite-scroller';
import ListItemCard from "./cards/ListItemCard";
import {SkeletonItem} from "./uiElements/SkelatonItem";
import {Checkbox} from "antd";

class ItemsList extends Component {
    getScrollPosition = () => {
        return this.props?.listRef?.current?.scrollTop;
    }

    render() {
        const {
            itemListInfo, detailsLink, editLink, selectingActive, allSelected,
            infiniteScroll, hasMore, useWindow, loading, className, listRef, withImage, building
        } = this.props;

        const {generalInfo = [], largeItems = [], actions, status, sizePercentage = 1.3} = this.props.itemListHeaderInfo || {};
        const largeItemsCount = largeItems?.length || 0;
        const defaultWidthPercent = 100 / generalInfo?.length;
        const largeItemsWidth = defaultWidthPercent * sizePercentage;
        const smallItemsWidth = largeItemsCount ?
            (100 - largeItemsCount * largeItemsWidth) / (generalInfo?.length - largeItemsCount)
            : defaultWidthPercent;

        return <div className={`items-list-wrapper ${className ? className : ""}`} ref={listRef}>
            {generalInfo && itemListInfo && !!itemListInfo.length &&
            <div className={`items-list-header`}>
                {selectingActive && <Checkbox checked={allSelected}
                                              onChange={this.props.selectAll}/>}
                <div className="general-info">
                    {!!generalInfo.length && generalInfo.map((item, index) => {
                        const width = largeItems.includes(index)
                            ? largeItemsWidth
                            : smallItemsWidth;
                        return <div className={"general-info-item"} style={{width: `${width}%`}}
                                    key={index}>{item}</div>
                    })}
                </div>
                <div className="items-header-actions-part">
                    {status && <div className="status">Կարգավիճակ</div>}
                    {actions && !!actions.length &&
                    <div className="actions"> Գործողություններ</div>}
                </div>
            </div>}
            {loading && (!itemListInfo || !itemListInfo.length)
                ? <div className={'items-list-content'}>
                    <SkeletonItem/>
                </div>
                : itemListInfo && !!itemListInfo.length ? (infiniteScroll ? <InfiniteScroll
                        hasMore={hasMore}
                        loadMore={this.props.loadMoreItems}
                        className={'items-list-content'}
                        pageStart={0}
                        useWindow={!!useWindow}
                        initialLoad={false}>
                        {itemListInfo.map((item) => {
                            return <ListItemCard key={item.id}
                                                 data={item}
                                                 status={status}
                                                 actions={actions}
                                                 isAvailable={item.isAvailable}
                                                 selectingActive={selectingActive}
                                                 largeItems={largeItems}
                                                 getScrollPosition={this.getScrollPosition}
                                                 largeItemsWidth={largeItemsWidth}
                                                 smallItemsWidth={smallItemsWidth}
                                                 detailsLink={detailsLink}
                                                 editLink={editLink}
                                                 withImage={withImage}
                                                 building={building}
                            />

                        })}
                    </InfiniteScroll>
                    :
                    <div className={'items-list-content'}>
                        {itemListInfo.map((item) => {
                            return <ListItemCard key={item.id}
                                                 data={item}
                                                 status={status}
                                                 actions={actions}
                                                 isAvailable={item.isAvailable}
                                                 selectingActive={selectingActive}
                                                 largeItems={largeItems}
                                                 getScrollPosition={this.getScrollPosition}
                                                 largeItemsWidth={largeItemsWidth}
                                                 smallItemsWidth={smallItemsWidth}
                                                 detailsLink={detailsLink}
                                                 editLink={editLink}
                                                 withImage={withImage}
                                                 building={building}
                            />

                        })}
                    </div>) :
                    <div className={'no-items'}>
                        <img src={noDataImage} alt={'no-items'}/>
                    </div>}
        </div>
    }
}

export default ItemsList
