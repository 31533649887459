import {ROOM_TYPE_CONSTS} from "../constants";

export const initialState = {
    roomTypesList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ROOM_TYPE_CONSTS.GET_ROOM_TYPES:
            return {
                ...state,
                roomTypesList: action.payload || [],
            };
        case ROOM_TYPE_CONSTS.CREATE_ROOM_TYPE:
            return {
                ...state,
                roomTypesList: [action.payload].concat(state.roomTypesList)
            };
        case ROOM_TYPE_CONSTS.UPDATE_ROOM_TYPE:
            return {
                ...state,
                roomTypesList: state.roomTypesList.map(item => item.id === action.payload.id
                    ? action.payload : item)
            };
        case ROOM_TYPE_CONSTS.DELETE_ROOM_TYPE_BY_ID:
            return {
                ...state,
                roomTypesList: state.roomTypesList.filter(item => item.id !== action.payload)
            };
        default:
            return state;
    }
}
