// Import packages
import React, { PureComponent } from "react";

// Import components
import { Progress, Tooltip } from "antd";

// Import styles
import "../../../assets/styles/media/media-uploader.scss";

//import utils
import { ImageCropper } from "./ImageCropper";
import { MaterialIcon } from "../../utils/Icon";
import { ACCEPT_IMAGE_TYPES, ACCEPT_LOGO_ADD_TYPES } from "../../../constants/acceptedTypes";
import { getFileIcon } from "../../../containers/media/utils/getFileIcon";
import { LinkButton } from "../../buttons/buttons";
import { FILE_TYPES } from "../../../constants/fileTypes";
import { InputGroup } from "../../uiElements/inputGroup";


export default class MediaUploader extends PureComponent {
    constructor (props) {
        super(props);
        this.state = {
            imageUrl: "",
            editingImage: null,
            editingImageIndex: null,
            croppingActive: false,
            minimize: false,
        };
        this.imageSelector = React.createRef();
        this.getCropImage = this.getCropImage.bind(this);
        this.closeCrop = this.closeCrop.bind(this);
        this.cacnel = this.cacnel.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
        this.resetImage = this.resetImage.bind(this);
        this.editImage = this.editImage.bind(this);
        this.addLogo = this.addLogo.bind(this);
    }


    editImage (editingImage, index) {
        this.setState({
            croppingActive: true,
            imageUrl: editingImage.imageUrl,
            editingImage: editingImage,
            editingImageIndex: index
        });
    }

    addLogo (index) {
        this.props.addLogoToImage(index)
    }

    getCropImage (image) {
        const { editingImageIndex } = this.state;
        const editingItem = this.props.addingFiles[editingImageIndex];
        const medias = [...this.props.addingFiles]
        medias[editingImageIndex] = {
            ...editingItem,
            ...image
        }
        this.props.getMedias(medias)
        this.closeCrop();
    }


    getUrlValue (index, value) {
        const editingItem = this.props.addingFiles[index];
        const medias = [...this.props.addingFiles]
        medias[index] = {
            ...editingItem,
            url: value
        }
        this.props.getMedias(medias)
    }

    deleteImage (position) {
        let cropImgList = this.props.addingFiles.filter((_, index) => index !== position);
        this.props.getMedias(cropImgList);
    }

    closeCrop () {
        this.setState({
            imageUrl: '',
            editingImage: null,
            editingImageIndex: null,
            croppingActive: false,
        })
    }

    cacnel () {
        this.props.cacnel();
        this.setState({
            imageUrl: '',
            croppingActive: false,
        })
    }

    resetImage () {
        const { editingImageIndex } = this.state;
        const editingItem = this.props.addingFiles[editingImageIndex];
        const medias = [...this.props.addingFiles];
        medias[editingImageIndex] = {
            imageUrl: editingItem.imageUrl,
            file: editingItem.file
        };
        this.props.getMedias(medias);
        this.closeCrop();
    }

    canAddLogo (extension) {
        return ACCEPT_LOGO_ADD_TYPES.some(acceptType => acceptType === extension)
    }

    render () {
        let { croppingActive, imageUrl, editingImage, isEditing } = this.state;
        const { aspect, addingFiles, uploading, progress, status, errors } = this.props;
        return <div className={'media-uploader-context'}>
            <div className="media-uploader-context-header">
                <div className={`progress ${(uploading || status) ? 'show' : ''}`}>
                    <Progress percent={progress} status={status || 'active'}/>
                </div>
                {uploading && this.props.minimizeUpload && <MaterialIcon className={'minimize-icon'}
                                                                         icon={'minimize'}
                                                                         onClick={this.props.minimizeUpload}/>}
            </div>
            {croppingActive && <ImageCropper
                imageUrl={imageUrl}
                isEditing={isEditing}
                editingImage={editingImage}
                aspect={aspect}
                resetImage={this.resetImage}
                getCropImage={this.getCropImage}
                closeCrop={this.closeCrop}
            />}
            <div className={`media-items-wrapper ${croppingActive ? 'croppingActive' : ''}`}>
                {
                    !!addingFiles?.length && addingFiles.map((item, index) => {
                        const fileType = item?.file?.type;
                        const extension = FILE_TYPES[fileType];
                        const isImage = ACCEPT_IMAGE_TYPES.some(acceptType => acceptType === extension)
                        const canAddLogo = this.canAddLogo(extension)
                        const imgUrl = isImage ? (item?.croppedPath || item?.imageUrl) :
                            getFileIcon(extension);
                        return <div className="media-item" key={index}>
                            <img src={imgUrl} className={'media-item-icon'} alt=""/>
                            <InputGroup inputType={"input"}
                                        type={"text"}
                                        placeholder={"Հղում"}
                                        name={"url"}
                                        value={item?.url}
                                        error={errors['url_' + index]}
                                        maxLength={500}
                                        onChange={({ value }) => this.getUrlValue(index, value)}>
                            </InputGroup>
                            <div className={'item-bottom-part'}>
                                <div className={'name'}>{item?.file?.name}</div>
                            </div>
                            <button
                                className={'btn delete-btn'}
                                onClick={() => {
                                    this.deleteImage(index)
                                }}>
                                <MaterialIcon icon="delete"/>
                            </button>
                            {isImage && <Tooltip color="#108ee9" title={'Փոփոխել'} placement={'left'}>
                                <button
                                    className={'btn edit-btn'}
                                    onClick={() => {
                                        this.editImage(item, index)
                                    }}>
                                    <MaterialIcon icon="edit"/>
                                </button>
                            </Tooltip>}
                            {canAddLogo &&
                            <Tooltip color="#108ee9" title={item.logoAdded ? 'Հեռացնել լոգո' : 'Ավելացնել լոգո'}
                                     placement={'left'}>
                                <button
                                    className={'btn add-icon-btn'}
                                    onClick={() => {
                                        this.addLogo(index)
                                    }}>
                                    <MaterialIcon icon={item.logoAdded ? "remove" : "add"}/>
                                </button>
                            </Tooltip>}
                        </div>
                    })
                }
            </div>
            <div className={'upload-buttons-wrapper'}>
                <LinkButton title={'Ավելացնել լոգո'}
                            className={'cancel-btn'}
                            cb={this.addLogo}
                            disabled={addingFiles?.every(item => !this.canAddLogo(FILE_TYPES[item?.file?.type]))}
                />
                <div>
                    <LinkButton title={'Չեղարկել'}
                                className={'cancel-btn'}
                                cb={this.cacnel}
                                disabled={uploading}
                    />
                    <LinkButton title={'Բեռնել'}
                                cb={this.props.uploadMedias}
                                disabled={croppingActive}
                                loadingWithTitle
                                loading={uploading}
                    />
                </div>
            </div>
        </div>
    }
}
