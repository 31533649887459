import {combineReducers} from "redux";

// import reducers
import auth from "./auth";
import utils from "./utils";
import languages from "./languages";
import moderators from "./moderators";
import staticTexts from "./staticTexts";
import media from "./media";
import composite from "./composite";
import filter from "./filter";
import product from "./product";
import slider from "./slider";
import customPages from "./customPages";
import building from "./building";
import roomType from "./roomType";
import banks from "./banks";
import about from "./about";
import badges from "./badges";
import subscribers from "./subscribers";
import reservations from "./reservations";
import projects from "./projects";
import logs from "./logs";


export default combineReducers({
    auth,
    utils,
    languages,
    media,
    composite,
    moderators,
    staticTexts,
    filter,
    product,
    slider,
    customPages,
    building,
    roomType,
    banks,
    about,
    badges,
    subscribers,
    reservations,
    projects,
    logs
});
