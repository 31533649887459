// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import components
import ItemsList from "../../components/ItemsList";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import moment from "moment";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetReservations, DeleteReservationById,} from "../../redux/actions";
import {deleteItem} from "../../utils/helperFunctions";
import {AREA_TYPES, RESERVATION_STATUS_TYPES} from "../../constants/constType";
import {CustomTabs} from "../../components/uiElements/Tabs";

const reservationTabs = [
    {
        name: "Բնակարան",
        key: "houseReservations",
        type: AREA_TYPES.APARTMENT.key,
    },
    {
        name: "Ավտոկայանատեղի",
        key: "parkingReservations",
        type: AREA_TYPES.PARKING.key
    },
]


class Reservations extends Component {
    constructor() {
        super();
        this.state = {
            activeTab: reservationTabs[0].key,
        }
        this.changeTab = this.changeTab.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
    }

    componentDidMount() {
        this.props.GetReservations({
            key: this.state?.activeTab,
            type: AREA_TYPES.APARTMENT.key
        });

    }

    loadMoreItems() {
        const {activeTab} = this.state
        this.props?.[activeTab]?.hasMore && this.props.GetReservations({
            reset: false,
            offset: this.props?.[activeTab]?.itemsList?.length,
            type: this.props?.[activeTab]?.type,
            key: activeTab
        });
    };

    changeTab(field, value) {
        this.setState({
            [field]: value,
        })

            this.props.GetReservations({
                type: reservationTabs.find(item => item?.key === value)?.type,
                key: value
            });
    }

    render() {
        let {requestLoading} = this.props;
        let {activeTab} = this.state;

        let itemListHeaderInfo = {
            generalInfo: activeTab === "parkingReservations" ? ["Անուն Ազգանուն", "Ավտոկայանատեղի", "Ամսաթիվ", "Կարգավիճակ"]
                : ["Անուն Ազգանուն", "Բնակարան", "Ամսաթիվ", "Կարգավիճակ"],
            largeItems: [0],
            actions: ["details", 'delete'],
        };

        let itemListInfo = this.props?.[activeTab]?.itemsList?.map((item) => {
            const {id, fullName, area, createdAt, status, phoneNumber, time} = item;
            return {
                id,
                values: activeTab !== 'callReservations'? [
                    fullName ?? '-',
                    "N " + (area?.areaNumber) ?? '-',
                    createdAt ? moment(createdAt).format('DD/MM/YYYY HH:mm') : '-',
                    {
                        title: <div className="color"
                                    style={{
                                        backgroundColor: RESERVATION_STATUS_TYPES[status]?.color,
                                        color: "white",
                                        border: "none",
                                        width: "120px"
                                    }}>
                            {RESERVATION_STATUS_TYPES[status]?.name}
                        </div>
                    }
                ] : [
                    fullName ?? '-',
                    phoneNumber ?? '-',
                    time || '-',
                ],
                deleteCb: deleteItem.bind(this, this.props.DeleteReservationById, 'ամրագրումը', id, activeTab)
            }
        });

        return <PageWrapper pageTitle={'Ամրագրվածներ'}>
            <CustomTabs changeTab={this.changeTab.bind(this, "activeTab")}
                        activeTab={activeTab}
                        tabsList={reservationTabs}/>
            <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                       itemListInfo={itemListInfo}
                       hasMore={this.props?.[activeTab]?.hasMore}
                       useWindow={true}
                       loadMoreItems={this.loadMoreItems}
                       infiniteScroll={true}
                       loading={requestLoading}
                       detailsLink={"reservations/update"}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetReservations,
    // GetCallReservations,
    DeleteReservationById,
    // DeleteCallReservationById
};

export default connect(mapStateToProps, mapDispatchToProps)(Reservations);

