import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'antd';

//import styles
import '../../assets/styles/media/add-folder-dropDown-card.scss';
import iconNewFolder from '../../assets/images/file-manager/new_folder.svg';

//import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import { AddFolder, GetMedia } from "../../redux/actions";
import AddFolderCard from "./AddFolderCard";


function AddMediaFolder(props){
    const [addFolderCardOpen, setAddFolderCardOpen] = useState(false);

    function toggleAddFolderCard(){
        setAddFolderCardOpen(!addFolderCardOpen)
    }


    function addFolder(folderName) {
        const { folderList, mediaItems } = props;
        const currentFolder = folderList && !!folderList.length && folderList[folderList.length - 1];
        const reqData = {
            name: folderName,
        };
        (currentFolder && currentFolder.id) && (reqData.parent = currentFolder.id);
        //(currentFolder && currentFolder.path) && (reqData.parentPath = currentFolder.path);
        //console.log(folderName)
        return props.AddFolder(reqData)
            .then(() => {
                props.GetMedia({
                    parent: currentFolder.id,
                    searchValue: mediaItems?.searchValue
                });
            })
    }

    return <Dropdown placement="bottomLeft"
                     trigger={["click"]}
                     className={"add-folder-btn"}
                     overlayClassName={`add-folder-dropdown ${props.folderDropDownClassName ? props.folderDropDownClassName : ''}`}
                     onVisibleChange={toggleAddFolderCard}
                     visible={addFolderCardOpen}
                     overlay={
                         <AddFolderCard closeModal={toggleAddFolderCard}
                                        clearCardData={addFolderCardOpen}
                                        addFolder={addFolder}/>
                     }>
        <div><img src={iconNewFolder} alt=""/>Ավելացնել Թղթապանակ</div>
    </Dropdown>
}


const mapDispatchToProps = {
    AddFolder,
    GetMedia,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddMediaFolder);
