// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import components
import ItemsList from "../../components/ItemsList";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import moment from "moment";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
   GetCallStatement, DeleteCallStatementById
} from "../../redux/actions";
import {deleteItem} from "../../utils/helperFunctions";
import {CustomTabs} from "../../components/uiElements/Tabs";

const reservationTabs = [
    // {
    //     name: "Այցելություն",
    //     key: "visitStatement",
    // },
    {
        name: "Հեռախոսազանգեր",
        key: "callStatement"
    },
]


class Statement extends Component {
    constructor() {
        super();
        this.state = {
            activeTab: reservationTabs[0].key,
        }
        this.changeTab = this.changeTab.bind(this);
    }

    componentDidMount() {
        if(this.state.activeTab === "callStatement"){
            this.props.GetCallStatement({
                type:"CALL"
            });
        } else {
            this.props.GetCallStatement({
                type:"VISIT"
            });
        }
    }

    changeTab(field, value) {
        this.setState({
            [field]: value,
        })
        if(value === "callStatement"){
            this.props.GetCallStatement({
                type:"CALL"
            });
        } else {
            this.props.GetCallStatement({
                type:"VISIT"
            });
        }
    }

    render() {
        let {requestLoading} = this.props;
        let {activeTab} = this.state;
        let itemListHeaderInfo = {
            generalInfo: activeTab === "callStatement"
                ? ["Անուն Ազգանուն", "Հեռախոս", "Ամսաթիվ"]
                : ["Անուն Ազգանուն", "Հեռախոս", "Ամսաթիվ"],
            largeItems: [0],
            actions: activeTab === "callStatement" ? ['delete'] : [ 'delete'],
        };
        let itemListInfo = this.props?.[activeTab]?.itemsList?.map((item) => {
            const {id, fullName, createdAt,phoneNumber} = item;
            return {
                id,
                values: activeTab === "callStatement"
                    ? [
                        fullName ?? '-',
                        phoneNumber || '-',
                        createdAt ? moment(createdAt).format('DD/MM/YYYY HH:mm') : '-',
                    ]
                    : [
                        fullName ?? '-',
                        phoneNumber || '-',
                        createdAt ? moment(createdAt).format('DD/MM/YYYY HH:mm') : '-',
                    ],
                deleteCb: deleteItem.bind(this, this.props.DeleteCallStatementById, 'Հայտեր', id, activeTab)
            }
        });
        return <PageWrapper pageTitle={'Հայտեր'}>
            <CustomTabs changeTab={this.changeTab.bind(this, "activeTab")}
                        activeTab={activeTab}
                        tabsList={reservationTabs}/>
            <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                       itemListInfo={itemListInfo}
                       useWindow={true}
                       loadMoreItems={this.loadMoreItems}
                       infiniteScroll={true}
                       loading={requestLoading}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetCallStatement,
    DeleteCallStatementById,
};

export default connect(mapStateToProps, mapDispatchToProps)(Statement);