export const ACCESS_PAGES = {
    projects: {
        key: 'BUILDING',
        name: 'Նախագիծ',
        icon: 'add_shopping_cart',
        crud: false,
        isHidden: true,
    },
    slider: {
        key: 'SLIDER',
        name: 'Սլայդեր',
        icon: 'amp_stories',
        crud: true,
        isHidden: false,
    },
    // menu: {
    //     key: 'MENU',
    //     name: 'Մենյու',
    //     icon: 'chrome_reader_mode',
    //     crud: true,
    //     isHidden: false,
    // },
    // customPages: {
    //     key: 'CUSTOM_PAGES',
    //     name: 'Դինամիկ էջեր',
    //     icon: 'web',
    //     crud: true,
    //     isHidden: false,
    // },
    media: {
        key: 'FILES',
        name: 'Ֆայլեր',
        icon: 'filter',
        crud: true,
        isHidden: false,
    },
    about: {
        key: 'ABOUT',
        name: 'Մեր Մասին',
        icon: 'category',
        crud: true,
        isHidden: false,
    },
    // 'about-slider': {
    //     key: 'ABOUT_SLIDER',
    //     name: 'Մեր Մասին Սլայդեր',
    //     icon: 'amp_stories',
    //     crud: true,
    //     isHidden: false,
    // },
    badges: {
        key: 'BADGES',
        name: 'Պիտակներ',
        icon: 'loyalty',
        crud: true,
        isHidden: false,
    },
    roomTypes: {
        key: 'ROOM_TYPES',
        name: 'Սենյակի տեսակներ',
        icon: 'vertical_split',
        crud: true,
        isHidden: false,
    },
    banks: {
        key: 'BANKS',
        name: 'Բանկեր',
        icon: 'account_balance',
        crud: true,
        isHidden: false,
    },

    subscribers: {
        key: 'SUBSCRIBERS',
        name: 'Բաժանորդագրված...',
        icon: 'style',
        crud: true,
        isHidden: false,
    },
    reservations: {
        key: 'RESERVATION',
        name: 'Ամրագրումներ',
        icon: 'book_online',
        crud: true,
        isHidden: false,
    },
    statement: {
        key: 'STATEMENT',
        name: 'Հայտեր',
        icon: 'bookmark',
        crud: false,
        isHidden: false,
    },
    languages: {
        key: 'LANGUAGES',
        name: 'Լեզուներ',
        icon: 'language',
        crud: true,
        isHidden: false,
    },
    contact: {
        key: 'CONTACTS',
        name: 'Կապ',
        icon: 'contact_phone',
        crud: true,
        isHidden: false,
    },
    staticTexts: {
        key: 'STATIC_TEXTS',
        name: 'Թարգմանություններ',
        icon: 'text_format',
        crud: true,
        isHidden: false,
    },
};
