// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

//import styles

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";

// Import utils
import {
    getInputValues,
} from "../../utils/helperFunctions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import { SendEmailToSubscribers } from "../../redux/actions";

class SendEmail extends Component {
    constructor(props){
        super(props);
        this.state = {
            fieldsData: {
                message: '',
            },
            errors: {},
            requestLoading: false,
        };
        this.getInputValues = getInputValues.bind(this);
        this.sendEmail = this.sendEmail.bind(this);

    }

    async sendEmail(){
        await this.setState({
            requestLoading: true
        });
        const { message, } = this.state.fieldsData;
        const errors = {};
        let result = true;

        if ( !message ) {
            errors.message = true;
            result = false;
        }
        if ( result ) {
            const reqData = {
                message,
            };
            this.props.SendEmailToSubscribers(reqData).finally(() => {
                this.setState({ requestLoading: false });
            })

        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
            })
        }
    }

    render(){
        const { fieldsData, errors, requestLoading, isEditing, } = this.state;

        return <PageWrapper pageTitle={'Ուղարկել Էլ․ նամակ'}>
            <div className="inputs-wrapper">
                <InputGroup inputType="textarea"
                            label="Տեքստ"
                            placeholder="Տեքստ"
                            name="message"
                            disabled={fieldsData?.type || fieldsData?.resourceId}
                            value={fieldsData?.message}
                            initValue={this.currentData?.message}
                            error={errors?.message}
                            maxLength={100}
                            onChange={this.getInputValues}/>
        {/*        <div className="editor-wrapper">
                    <label>Տեքստ <span className={'required-badge'}>*</span></label>
                    <Editor value={fieldsData?.message || ''}
                            name={'message'}
                            initValue={this.currentData?.message}
                            error={errors?.message}
                            onChange={this.getInputValues}/>
                </div>*/}
            </div>
            <div className="flex-wrapper-right">
                <LinkButton title={'Ուղարկել'}
                            loading={requestLoading}
                            disabled={isEditing && !this.updatedDataMap.size}
                            cb={this.sendEmail}/>
            </div>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    SendEmailToSubscribers
};

export default connect(mapStateToProps, mapDispatchToProps)(SendEmail)
