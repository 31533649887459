import { ABOUT_CONSTS } from "../constants";

export const initialState = {
    aboutSliderList: [],
    about: null
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case ABOUT_CONSTS.SET_ABOUT_DATA:
            return {
                ...state,
                about: action.payload ,
            };
        case ABOUT_CONSTS.GET_ABOUT_SLIDERS:
            return {
                ...state,
                aboutSliderList: action.payload || [],
            };
        case ABOUT_CONSTS.CREATE_ABOUT_SLIDER:
            return {
                ...state,
                aboutSliderList: [action.payload].concat(state.aboutSliderList)
            };
        case ABOUT_CONSTS.DELETE_ABOUT_SLIDER_BY_ID:
            return {
                ...state,
                aboutSliderList: state.aboutSliderList.filter(item => item.id !== action.payload)
            };
        default:
            return state;
    }
}
