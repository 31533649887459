import {AUTH_CONSTS, LOG_CONSTS} from "../constants";

export const initialState = {
    newLogSocketConnected: false,
    newLogList: [],
    newLogHasMore: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOG_CONSTS.GET_LOGS:
            // console.log('GET_NEW_LOG', action.payload);
            return {
                ...state,
                newLogHasMore: action.payload.hasMore,
                newLogList: action.payload.reset ? action.payload.data :
                    [...state.newLogList, ...action.payload.data]
            };

        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
