import React, {useState} from "react";

import {HeaderLogo} from "./headerLogo";
import {MenuDropdown} from "./MenuDropdown";
import SideMenu from "../uiElements/sideMenu/SideMenu";

import "../../assets/styles/headerStyles/mainHeader.scss";

export function MainHeader(props) {
    const {toggleMenu, LogOut, isAdmin, leftSideMenuOpen, user} = props;

    const [showMenu, setShowMenu] = useState(false)

    function toggleSideMenu(state){
        setShowMenu(state)
    }
    return <header className="main-header">
        <HeaderLogo toggleMenu={toggleMenu}
                    leftSideMenuOpen={leftSideMenuOpen}/>
        <div className="right-part">
            <MenuDropdown LogOut={LogOut}
                          isAdmin={isAdmin}
                          user={user}/>
            {isAdmin && <button className={'more-btn'} onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                toggleSideMenu(true)
            }}>
                Show menu
            </button>}
        </div>
        {isAdmin && <SideMenu showMenu={showMenu} toggleMenu={toggleSideMenu}/>}
    </header>
}