import {request, _urlCustomPages} from "../api";
import {CUSTOM_PAGE_CONSTS} from "../constants";
import {history} from "../../configs/history";
import {notificationSuccess} from "../../components/uiElements/Notifications";

export const GetCustomPages = () => {
    const requestData = {
        url: _urlCustomPages,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: CUSTOM_PAGE_CONSTS.GET_CUSTOM_PAGES,
                    payload: data
                });
            })
    }
};

export const DeleteCustomPageById = id => {
    const requestData = {
        url: `${_urlCustomPages}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: CUSTOM_PAGE_CONSTS.DELETE_CUSTOM_PAGE_BY_ID,
                    payload: id
                });
            })
    }
};

export const CreateCustomPage = data => {
    const requestData = {
        url: _urlCustomPages,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: CUSTOM_PAGE_CONSTS.CREATE_CUSTOM_PAGE,
                    payload: res?.data
                });
                notificationSuccess({
                    description: "Էջը հաջողությամբ ստեղծվեց!"
                })
                history.push('/customPages')
            })
    }
};

export const UpdateCustomPageById = (id, data) => {
    const requestData = {
        url: `${_urlCustomPages}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(async res => {
                dispatch({
                    type: CUSTOM_PAGE_CONSTS.UPDATE_CUSTOM_PAGE,
                    payload: res?.data
                });
                notificationSuccess({
                    description: "Էջը հաջողությամբ փոփոխվեց!"
                })
                history.push('/customPages')
            })
    }
};
