import {initialState as auth} from "../reducers/auth";
import {initialState as utils} from "../reducers/utils";
import {initialState as languages} from "../reducers/languages";
import {initialState as moderators} from "../reducers/moderators";
import {initialState as staticTexts} from "../reducers/staticTexts";
import {initialState as media} from "../reducers/media";
import {initialState as composite} from "../reducers/composite";
import {initialState as filter} from "../reducers/filter";
import {initialState as product} from "../reducers/product";
import {initialState as slider} from "../reducers/slider";
import {initialState as customPages} from "../reducers/customPages";
import {initialState as building} from "../reducers/building";
import {initialState as roomType} from "../reducers/roomType";
import {initialState as banks} from "../reducers/banks";
import {initialState as about} from "../reducers/about";
import {initialState as badges} from "../reducers/badges";
import {initialState as subscribers} from "../reducers/subscribers";
import {initialState as reservations} from "../reducers/reservations";
import {initialState as projects} from "../reducers/projects";
import {initialState as logs} from "../reducers/logs";


const reducers = {
    auth,
    utils,
    composite,
    languages,
    moderators,
    staticTexts,
    media,
    filter,
    product,
    slider,
    customPages,
    building,
    roomType,
    banks,
    about,
    badges,
    subscribers,
    reservations,
    projects,
    logs
};

export default state => {
    const sData = {};
    Object.keys(reducers).forEach(selectorKey => {
        Object.keys(reducers[selectorKey]).forEach(key => {
            sData[key] = state[selectorKey][key];
        });
    });
    return sData;
};

