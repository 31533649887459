import {request, _urlBadges} from "../api";
import {BADGE_CONSTS} from "../constants";
import {history} from "../../configs/history";
import {notificationSuccess} from "../../components/uiElements/Notifications";

export const GetBadges = () => {
    const requestData = {
        url: _urlBadges,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: BADGE_CONSTS.GET_BADGES,
                    payload: data
                });
            })
    }
};


export const DeleteBadgeById = id => {
    const requestData = {
        url: `${_urlBadges}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: BADGE_CONSTS.DELETE_BADGE_BY_ID,
                    payload: id
                });
            })
    }
};

export const CreateBadge = data => {
    const requestData = {
        url: _urlBadges,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: BADGE_CONSTS.CREATE_BADGE,
                    payload: res?.data
                });
                notificationSuccess({
                    description: "Պիտակը հաջողությամբ ստեղծվեց!"
                })
                history.push('/badges')
            })
    }
};

export const UpdateBadgeById = (id, data) => {
    const requestData = {
        url: `${_urlBadges}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(async res => {
                dispatch({
                    type: BADGE_CONSTS.UPDATE_BADGE,
                    payload: res?.data
                });
                notificationSuccess({
                    description: "Պիտակը հաջողությամբ փոփոխվեց!"
                })
                history.push('/badges')
            })
    }
};
