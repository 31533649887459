export const StaticTextFields = {
    header: {
        pageName: 'Header',
        list: [
            {
                label: "Header-Project",
                key: "header_item_project"
            },
            {
                label: "Header-Info",
                key: "header_item_info"
            },
            {
                label: "Header-Time-Moscow",
                key: "time_Moscow"
            },
            {
                label: "Header-Time-Yerevan",
                key: "time_Yerevan"
            },
            {
                label: "Header-Time-Los Angeles",
                key: "time_Los_Angeles"
            },
            {
                label: "Header-Time-Paris",
                key: "time_Paris"
            },
        ]
    },
    menu: {
        pageName: 'Menu',
        list: [
            {
                label: "Menu - about",
                key: "menu_about"
            },
            {
                label: "Menu - contact",
                key: "menu_contact"
            },
            {
                label: "Menu - apartments",
                key: "menu_apartments"
            },
            {
                label: "Menu - calculator",
                key: "menu_calculator"
            },
        ]
    },
    footer: {
        pageName: 'Footer',
        list: [
            {
                label: "Footer - section security title",
                key: "footer_section_security_title"
            },
            {
                label: "Footer - section privacy link",
                key: "footer_section_privacy"
            },
            {
                label: "Footer - section terms and conditions link",
                key: "footer_section_terms_and_conditions"
            },
            {
                label: "Footer - section company title",
                key: "footer_section_company_title"
            },
            {
                label: "Footer - section about link",
                key: "footer_section_about"
            },
            {
                label: "Footer - section filters link",
                key: "footer_section_filters"
            },
            {
                label: "Footer - section calculator link",
                key: "footer_section_calculator"
            },
            {
                label: "Footer - section contact title",
                key: "footer_section_contact_title"
            },
            {
                label: "Footer - section copyright text",
                key: "footer_section_copyright_text"
            },
            {
                label: "Footer - section developed by text",
                key: "footer_section_developed_by_text"
            },
            {
                label: "Footer - section address",
                key: "footer_section_address"
            },
            {
                label: "Footer - section address 2",
                key: "footer_section_address2"
            },
            {
                label: "Footer - section questions",
                key: "footer_section_questions"
            },
            {
                label: "Footer - section office address",
                key: "footer_section_office_address"
            },
            {
                label: "Footer - section office title",
                key: "footer_section_office_title"
            },
        ]
    },
    routs: {
        pageName: 'Routs',
        list: [
            {
                label: "Rout - home page",
                key: "rout_item_home"
            },
            {
                label: "Rout - buildings",
                key: "rout_item_buildings"
            },
            {
                label: "Rout - title building",
                key: "rout_item_title_building"
            },
            {
                label: "Rout - building",
                key: "rout_item_building"
            },
            {
                label: "Rout - title floor",
                key: "rout_item_title_floor"
            },
            {
                label: "Rout - floor",
                key: "rout_item_floor"
            },
            {
                label: "Rout - house",
                key: "rout_item_house"
            },
            {
                label: "Rout - office",
                key: "rout_item_office"
            },
            {
                label: "Rout - title house",
                key: "rout_item_title_house"
            },
            {
                label: "Rout - title office",
                key: "rout_item_title_office"
            },
            {
                label: "Rout - title parking",
                key: "rout_item_title_parking"
            },
            {
                label: "Rout - title address",
                key: "rout_item_address_title"
            },
            {
                label: "Rout - title filter",
                key: "rout_item_filter_title"
            },
            {
                label: "Rout - text filter",
                key: "rout_item_filter_text"
            },
            {
                label: "Rout - filter title choose house",
                key: "rout_item_filter_title_choose_house"
            },
            {
                label: "Rout - calculator text",
                key: "rout_item_calculator_text"
            },
        ]
    },
    homepage: {
        pageName: 'Homepage',
        list: [
            {
                label: "Homepage - slider project view",
                key: "homepage_slider_project_view"
            },
            {
                label: "Homepage - slider apartment placeholder",
                key: "homepage_slider_apartment_placeholder"
            },
            {
                label: "Homepage - slider rooms count placeholder",
                key: "homepage_slider_rooms_count_placeholder"
            },
            {
                label: "Homepage - slider project placeholder",
                key: "homepage_slider_project_placeholder"
            },
            {
                label: "Homepage - slider from",
                key: "homepage_slider_from"
            },
            {
                label: "Homepage - slider to",
                key: "homepage_slider_to"
            },
            {
                label: "Homepage -Best-Offer-Title",
                key: "homepage_best_offer_title"
            },
            {
                label: "Homepage -Best-Offer-Description",
                key: "homepage_best_offer_description"
            },
            {
                label: "Homepage - slider block houses button",
                key: "homepage_slider_button_houses"
            },
            {
                label: "Homepage - about box title",
                key: "homepage_about_box_title"
            },
            {
                label: "Homepage - about box description",
                key: "homepage_about_box_description"
            },
            {
                label: "Homepage - about features title",
                key: "homepage_about_features_title"
            },
            {
                label: "Homepage - about feature 1",
                key: "homepage_about_feature_1"
            },
            {
                label: "Homepage - about feature 2",
                key: "homepage_about_feature_2"
            },
            {
                label: "Homepage - about feature 3",
                key: "homepage_about_feature_3"
            },
            {
                label: "Homepage - about question",
                key: "homepage_about_question"
            },
            {
                label: "Homepage - about question button",
                key: "homepage_about_question_button"
            },
            {
                label: "Homepage - about projects count",
                key: "homepage_about_projects_count"
            },
            {
                label: "Homepage - about years count",
                key: "homepage_about_years_count"
            },
            {
                label: "Homepage - about prof count",
                key: "homepage_about_prof_count"
            },
            {
                label: "Homepage - about sub title",
                key: "homepage_about_sub_title"
            },
            {
                label: "Homepage - about question text",
                key: "homepage_about_questions_text"
            },
            {
                label: "Homepage - projects question",
                key: "homepage_projects_question"
            },
            {
                label: "Homepage - projects question link",
                key: "homepage_projects_question_link"
            },
            {
                label: "Homepage - projects about btn",
                key: "homepage_projects_about_btn"
            },
            {
                label: "Homepage - futures title line 1",
                key: "homepage_futures_title_line_1"
            },
            {
                label: "Homepage - futures title line 2",
                key: "homepage_futures_title_line_2"
            },
            {
                label: "Homepage - futures question",
                key: "homepage_futures_question"
            },
            {
                label: "Homepage - futures calculate",
                key: "homepage_futures_calculate"
            },
            {
                label: "Homepage - futures here",
                key: "homepage_futures_here"
            },
            {
                label: "Homepage - futures item title 1",
                key: "homepage_futures_item_title_1"
            },
            {
                label: "Homepage - futures item description 1",
                key: "homepage_futures_item_description_1"
            },
            {
                label: "Homepage - futures item title 2",
                key: "homepage_futures_item_title_2"
            },
            {
                label: "Homepage - futures item description 2",
                key: "homepage_futures_item_description_2"
            },
            {
                label: "Homepage - futures item title 3",
                key: "homepage_futures_item_title_3"
            },
            {
                label: "Homepage - futures item description 3",
                key: "homepage_futures_item_description_3"
            },
            {
                label: "Homepage - futures item title 4",
                key: "homepage_futures_item_title_4"
            },
            {
                label: "Homepage - futures item description 4",
                key: "homepage_futures_item_description_4"
            },
            {
                label: "Homepage - contact title",
                key: "homepage_contact_title"
            },
            {
                label: "Homepage - contact description text",
                key: "homepage_contact_text"
            },
            {
                label: "Homepage - contact email placeholder",
                key: "homepage_contact_placeholder_email"
            },
            {
                label: "Homepage - contact subscribe button",
                key: "homepage_contact_button_send"
            },
        ]
    },
    about: {
        pageName: 'About',
        list: [
            {
                label: "About - projects count",
                key: "about_page_count_projects"
            },
            {
                label: "About - commercial count",
                key: "about_page_count_commercials"
            },
            {
                label: "About - houses count",
                key: "about_page_count_houses"
            },
            {
                label: "About - workers count",
                key: "about_page_count_workers"
            },
            {
                label: "About - contact button",
                key: "about_page_btn_contact"
            },
            {
                label: "About - houses button",
                key: "about_page_btn_houses"
            },
            {
                label: "About -years count",
                key: "homepage_years_count"
            },
            {
                label: "About -success projects",
                key: "homepage_success_projects"
            },
            {
                label: "About - commercials count",
                key: "homepage_commercials_count"
            },
        ]
    },
    contact: {
        pageName: 'Contact',
        list: [
            {
                label: "Contact - page title",
                key: "contacts_page_title"
            },
            {
                label: "Contact - page left side subTitle",
                key: "contacts_page_left_subTitle"
            },
            {
                label: "Contact - page left side text",
                key: "contacts_page_left_text"
            },
            {
                label: "Contact - page info phone title",
                key: "contacts_page_info_phone_title"
            },
            {
                label: "Contact - page info email title",
                key: "contacts_page_info_email_title"
            },
            {
                label: "Contact - page info address title",
                key: "contacts_page_info_address_title"
            },
            {
                label: "Contact - page right side subTitle",
                key: "contacts_page_right_subTitle"
            },
            {
                label: "Contact - page right side text",
                key: "contacts_page_right_text"
            },
            {
                label: "Contact - page name field placeholder",
                key: "contacts_page_placeholder_name"
            },
            {
                label: "Contact - page email field placeholder",
                key: "contacts_page_placeholder_email"
            },
            {
                label: "Contact - page phoneNumber field placeholder",
                key: "contacts_page_placeholder_phoneNumber"
            },
            {
                label: "Contact - page message field placeholder",
                key: "contacts_page_placeholder_message"
            },
            {
                label: "Contact - page send message button",
                key: "contacts_page_btn_send_message"
            },
            {
                label: "Contact - page address",
                key: "contacts_page_address"
            },
        ]
    },
    mouse: {
        pageName: 'Mouse Info',
        list: [
            {
                label: "Mouse Info - building",
                key: "mouse_info_building"
            },
            {
                label: "Mouse Info - floor",
                key: "mouse_info_floor"
            },
            {
                label: "Mouse Info - house",
                key: "mouse_info_house"
            },
            {
                label: "Mouse Info - office",
                key: "mouse_info_office"
            },
            {
                label: "Mouse Info - room",
                key: "mouse_info_room"
            },
            {
                label: "Mouse Info - house is available text",
                key: "mouse_info_is_available"
            },
        ]
    },
    house: {
        pageName: 'House',
        list: [
            {
                label: "House - right info title",
                key: "house_right_info_title"
            },
            {
                label: "House - right info upload button",
                key: "house_right_info_upload_btn"
            },
            {
                label: "House - checkbox identical houses",
                key: "house_checkbox_identical_houses"
            },
            {
                label: "House - right info cadastral area",
                key: "house_right_info_cadastral_area"
            },
            {
                label: "House - right info area",
                key: "house_right_info_area"
            },
            {
                label: "House - right info renovated checkbox",
                key: "house_right_info_renovated_checkbox"
            },
            {
                label: "House - right info contractual price",
                key: "house_right_info_price_contractual"
            },
            {
                label: "House - right info reserve now button",
                key: "house_right_info_reserve_now_btn"
            },
            {
                label: "House - right info link text",
                key: "house_right_info_link_text"
            },
            {
                label: "House - right info link blue text",
                key: "house_right_info_link_blue_text"
            },
            {
                label: "House - right info monthly payment",
                key: "house_right_info_monthly_payment"
            },
            {
                label: "House -blocks buildings title",
                key: "house_blocks_buildings_title"
            },
            {
                label: "House -blocks buildings number",
                key: "house_blocks_buildings_number"
            },
            {
                label: "House - blocks areas title",
                key: "house_blocks_areas_title"
            },
            {
                label: "House - blocks areas number",
                key: "house_blocks_areas_number"
            },
            {
                label: "House - blocks zones title",
                key: "house_blocks_zones_title"
            },
            {
                label: "House - blocks zones number",
                key: "house_blocks_zones_number"
            },
            {
                label: "House - blocks parking place title",
                key: "house_blocks_parking_place_title"
            },
            {
                label: "House - blocks parking place number",
                key: "house_blocks_parking_place_number"
            },
            {
                label: "House -available apartments",
                key: "house_available_apartments"
            },
            {
                label: "House - out of",
                key: "house_out_of"
            },
        ]
    },
    measurementUnits: {
        pageName: 'Measurement Units',
        list: [
            {
                label: "Measurement Unit - meter",
                key: "measurement_unit_meter"
            },
            {
                label: "Measurement Unit - dram",
                key: "measurement_unit_dram"
            },
        ]
    },
    reserveModal: {
        pageName: 'Reserve Modal',
        list: [
            {
                label: "Reserve Modal - title house",
                key: "modal_title_house"
            },
            {
                label: "Reserve Modal - title parking",
                key: "modal_title_parking"
            },
            {
                label: "Reserve Modal - title office",
                key: "modal_title_office"
            },
            {
                label: "Reserve Modal - success message",
                key: "modal_success_message"
            },
            {
                label: "Reserve Modal - error message",
                key: "modal_error_message"
            },
            {
                label: "Reserve Modal - name field label",
                key: "modal_name_label"
            },
            {
                label: "Reserve Modal - email field label",
                key: "modal_email_label"
            },
            {
                label: "Reserve Modal - phone field label",
                key: "modal_phone_label"
            },
            {
                label: "Reserve Modal - house field label",
                key: "modal_house_number_label"
            },
            {
                label: "Reserve Modal - parking label",
                key: "modal_parking_label"
            },
            {
                label: "Reserve Modal - cancel button",
                key: "modal_btn_cancel"
            },
            {
                label: "Reserve Modal - close button",
                key: "modal_btn_close"
            },
            {
                label: "Reserve Modal - save button",
                key: "modal_btn_save"
            },
        ]
    },
    callModal: {
        pageName: 'Call Modal',
        list: [
            {
                label: "Call Modal - title",
                key: "call_modal_title"
            },
            {
                label: "Call Modal - time placeholder",
                key: "call_time_placeholder"
            },
            {
                label: "Call Model - time picker placeholder",
                key: "call_time_picker_placeholder"
            },
            {
                label: "Call modal cancel",
                key: "call_modal_cancel"
            },
            {
                label: "Call modal send",
                key: "call_modal_send"
            },
        ]
    },
    filter: {
        pageName: 'Filters',
        list: [
            {
                label: "Filters - floors title",
                key: "filter_floors_title"
            },
            {
                label: "Filters - sort placeholder",
                key: "filter_sort_placeholder"
            },
            {
                label: "Filters - floors dropdown cancel button",
                key: "filter_floors_dropdown_btn_cancel"
            },
            {
                label: "Filters - floors dropdown remember button",
                key: "filter_floors_dropdown_btn_remember"
            },
            {
                label: "Filters - rooms count title",
                key: "filter_rooms_count_title"
            },
            {
                label: "Filters - area title",
                key: "filter_area_title"
            },
            {
                label: "Filters - min area placeholder",
                key: "filter_min_area_placeholder"
            },
            {
                label: "Filters - max area placeholder",
                key: "filter_max_area_placeholder"
            },
            {
                label: "Filters - price title",
                key: "filter_price_title"
            },
            {
                label: "Filters - min price placeholder",
                key: "filter_min_price_placeholder"
            },
            {
                label: "Filters - max price placeholder",
                key: "filter_max_price_placeholder"
            },
            {
                label: "Filters - clear filters button",
                key: "filter_clear_filters"
            },
            {
                label: "Filters - showing count",
                key: "filter_showing_count"
            },
            {
                label: "Filters - sort type rooms count",
                key: "filter_sort_type_rooms_count"
            },
            {
                label: "Filters - sort type area",
                key: "filter_sort_type_area"
            },
            {
                label: "Filters - sort type price",
                key: "filter_sort_type_price"
            },
            {
                label: "Filters -rooms total",
                key: "filter_rooms_total"
            },
            {
                label: "Filters -from",
                key: "filter_from"
            },
            {
                label: "Filters - to",
                key: "filter_to"
            },
            {
                label: "Filters -currency data",
                key: "filter_currency_data"
            },
            {
                label: "Filters -currency exchange",
                key: "filter_currency_exchange"
            },
            {
                label: "Filters - currency sale",
                key: "filter_currency_sale"
            },
            {
                label: "Filters - currency buy",
                key: "filter_currency_buy"
            },
            {
                label: "Filters - currency calculator title",
                key: "filter_currency_calculator_title"
            },
            {
                label: "Filters - currency input placeholder",
                key: "filter_currency_input_placeholder"
            },
            {
                label: "Share title",
                key: "share_title"
            },
            {
                label: "Print title",
                key: "print_title"
            },
            {
                label: "Filter not found",
                key: "filter_not_found"
            },
            {
                label: "Filter apartment",
                key: "filter_apartment"
            },
            {
                label: "Filter parking",
                key: "filter_parking"
            },
            {
                label: "Filter commerce",
                key: "filter_commerce"
            },
            {
                label: "Filter title",
                key: "filter_title"
            },
        ]
    },
    sortTypes: {
        pageName: 'Types',
        list: [
            {
                label: "Sort Type - price increase",
                key: "sort_by_price_increase"
            },
            {
                label: "Sort Type - price reduction",
                key: "sort_by_price_reduction"
            },
            {
                label: "Sort Type - area increase",
                key: "sort_by_area_increase"
            },
            {
                label: "Sort Type - area reduction",
                key: "sort_by_area_reduction"
            },
            {
                label: "Sort Type - rooms count increase",
                key: "sort_by_rooms_count_increase"
            },
            {
                label: "Sort Type - rooms count reduction",
                key: "sort_by_rooms_count_reduction"
            },
            {
                label: "Area Type - apartment",
                key: "area_type_apartment"
            },
            {
                label: "Area Type - office",
                key: "area_type_office"
            },
            {
                label: "Area Type - parking",
                key: "area_type_perking"
            },
        ]
    },
    houseCard: {
        pageName: 'House Card',
        list: [
            {
                label: "House Card - price contractual",
                key: "house_card_price_contractual"
            },
            {
                label: "House Card - area title",
                key: "house_card_area_title"
            },
            {
                label: "House Card - floor title",
                key: "house_card_floor_title"
            },
            {
                label: "House Card - rooms title",
                key: "house_card_rooms_title"
            },
            {
                label: "House Card - reverse button",
                key: "house_card_reverse_btn"
            },
            {
                label: "House Card -name",
                key: "house_card_name"
            },
            {
                label: "House Card -price",
                key: "house_card_price"
            },
            {
                label: "House Card - slider title",
                key: "house_card_slider_title"
            },
        ]
    },
    calculator: {
        pageName: 'Calculator',
        list: [
            {
                label: "Calculator - left side title",
                key: "calculator_left_title"
            },
            {
                label: "Calculator - hypothec price label",
                key: "calculator_hypothec_price_label"
            },
            {
                label: "Calculator - hypothec price placeholder",
                key: "calculator_hypothec_price_placeholder"
            },
            {
                label: "Calculator - hypothec advance payment label",
                key: "calculator_hypothec_advancePayment_label"
            },
            {
                label: "Calculator - hypothec advance payment placeholder",
                key: "calculator_hypothec_advancePayment_placeholder"
            },
            {
                label: "Calculator - hypothec date label",
                key: "calculator_hypothec_date_label"
            },
            {
                label: "Calculator - hypothec date placeholder",
                key: "calculator_hypothec_date_placeholder"
            },
            {
                label: "Calculator - hypothec type label",
                key: "calculator_hypothec_type_label"
            },
            {
                label: "Calculator - hypothec type placeholder",
                key: "calculator_hypothec_type_placeholder"
            },
            {
                label: "Calculator - repayment type label",
                key: "calculator_repayment_type_label"
            },
            {
                label: "Calculator - repayment type placeholder",
                key: "calculator_repayment_type_placeholder"
            },
            {
                label: "Calculator - hypothec type mount",
                key: "calculator_hypothec_type_mount"
            },
            {
                label: "Calculator - hypothec type year",
                key: "calculator_hypothec_type_year"
            },
            {
                label: "Calculator - repayment type annuity",
                key: "calculator_repayment_type_annuity"
            },
            {
                label: "Calculator - repayment type differentiated",
                key: "calculator_repayment_type_differentiated"
            },
            {
                label: "Calculator - hypothec bank title label",
                key: "calculator_bank_title_label"
            },
            {
                label: "Calculator - hypothec bank title placeholder",
                key: "calculator_bank_title_placeholder"
            },
            {
                label: "Calculator - hypothec percent label",
                key: "calculator_hypothec_percent_label"
            },
            {
                label: "Calculator - hypothec percent placeholder",
                key: "calculator_hypothec_percent_placeholder"
            },
            {
                label: "Calculator - cancel button",
                key: "calculator_cancel_btn"
            },
            {
                label: "Calculator - calculate button",
                key: "calculator_calculate_btn"
            },
            {
                label: "Calculator - right side title",
                key: "calculator_right_title"
            },
            {
                label: "Calculator - right box hypothec price title",
                key: "calculator_right_box_hypothec_price_title"
            },
            {
                label: "Calculator - right box hypothec date title",
                key: "calculator_right_box_hypothec_date_title"
            },
            {
                label: "Calculator - right box type mount",
                key: "calculator_right_box_type_mount"
            },
            {
                label: "Calculator - right box type year",
                key: "calculator_right_box_type_year"
            },
            {
                label: "Calculator - right box hypothec percent title",
                key: "calculator_right_box_hypothec_percent_title"
            },
            {
                label: "Calculator - right box main price title",
                key: "calculator_right_box_main_price_title"
            },
            {
                label: "Calculator - right box advance payment title",
                key: "calculator_right_box_advance_payment_title"
            },
            {
                label: "Calculator - right box percent title",
                key: "calculator_right_box_percent_title"
            },
            {
                label: "Calculator - right box income tax title",
                key: "calculator_right_box_income_tax_title"
            },
            {
                label: "Calculator - right box monthly payment title",
                key: "calculator_right_box_monthly_payment_title"
            },
            {
                label: "Calculator - table column month",
                key: "calculator_table_column_month"
            },
            {
                label: "Calculator - table column percent",
                key: "calculator_table_column_percent"
            },
            {
                label: "Calculator - table main amount",
                key: "calculator_table_main_amount"
            },
            {
                label: "Calculator - table monthly payment",
                key: "calculator_table_monthly_payment"
            },
            {
                label: "Calculator - title",
                key: "calculator_title"
            },
            {
                label: "Calculator - title description",
                key: "calculator_title_description"
            },
        ]
    }
}