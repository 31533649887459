import { request, _urlAboutSlider, _urlAbout } from "../api";
import { ABOUT_CONSTS } from "../constants";
import { history } from "../../configs/history";
import { notificationSuccess } from "../../components/uiElements/Notifications";

export const GetAboutData = () => {
    const requestData = {
        url: _urlAbout,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: ABOUT_CONSTS.SET_ABOUT_DATA,
                    payload: data
                });
            })
    }
};


export const UpdateAboutData = (data) => {
    const requestData = {
        url: `${_urlAbout}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: ABOUT_CONSTS.SET_ABOUT_DATA,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Տվյալները հաջողությամբ փոփոխվեց!"
                })
                return res.data
            })
    }
};


export const UpdateAboutItemData = (slug,type,data) => {
    const requestData = {
        url: `${_urlAbout}/${type}/${slug}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: ABOUT_CONSTS.SET_ABOUT_DATA,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Տվյալները հաջողությամբ փոփոխվեց!"
                })
                history.push('/about')
                return res.data
            })
    }
};


export const GetAboutSliders = () => {
    const requestData = {
        url: _urlAboutSlider,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: ABOUT_CONSTS.GET_ABOUT_SLIDERS,
                    payload: data
                });
            })
    }
};


export const DeleteAboutSliderById = id => {
    const requestData = {
        url: `${_urlAboutSlider}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: ABOUT_CONSTS.DELETE_ABOUT_SLIDER_BY_ID,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};

export const CreateAboutSlider = data => {
    const requestData = {
        url: _urlAboutSlider,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: ABOUT_CONSTS.CREATE_ABOUT_SLIDER,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Սլայդերը հաջողությամբ ստեղծվեց!"
                })
                history.push('/about-slider')
            })
            .catch(() => {
            })
    }
};

export const UpdateAboutSliderById = (id, data) => {
    const requestData = {
        url: `${_urlAboutSlider}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: ABOUT_CONSTS.UPDATE_ABOUT_SLIDER_BY_ID,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Սլայդերը հաջողությամբ փոփոխվեց!"
                })
                history.push('/about-slider')
            })
            .catch(() => {
            })
    }
};

export const UpdateAboutSlidersPositions = items => {
    const requestData = {
        url: _urlAboutSlider,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                notificationSuccess({
                    description: "Սլայդերը հաջողությամբ թարմացվեց!"
                })
            })
            .catch(() => {
            })
    }
};
