//--------------------------------------- Official-server --------------------------------------------

const ADMIN_URL =  "https://api.retroshin.am/admin/api/v1";
export const HOST_MEDIA_URL =  "https://api.retroshin.am/admin";

//--------------------------------------- 4Steps-server --------------------------------------------

// const ADMIN_URL =  "http://104.248.81.88:3002/api/v1";
// export const HOST_MEDIA_URL =  "http://104.248.81.88:3002";

//-----------------Local---------------------
// const ADMIN_URL =  "http://localhost:4010/api/v1";
// export const HOST_MEDIA_URL =  "http://localhost:4010";



export const _urlOauth = ADMIN_URL + "/jwt";
export const _urlMedia = ADMIN_URL + "/files";
export const _urlLanguages = ADMIN_URL + "/languages";
export const _urlOthers = ADMIN_URL + "/others";
export const _urlContact = ADMIN_URL + "/contacts";
export const _urlModerator = ADMIN_URL + "/moderators";
export const _urlStaticTexts = ADMIN_URL + "/static-texts";
export const _urlFilters = ADMIN_URL + "/filters"
export const _urlSlider = ADMIN_URL + "/slider";
export const _urlProducts = ADMIN_URL + "/products";
export const _urlCustomPages = ADMIN_URL + "/custom-pages";
export const _urlRoomTypes = ADMIN_URL + "/rooms-types";
export const _urlBanks = ADMIN_URL + "/banks";
export const _urlProjects = ADMIN_URL + "/projects";
export const _urlBuildings = ADMIN_URL + "/buildings";
export const _urlAreas = ADMIN_URL + "/areas";
export const _urlParking = ADMIN_URL + "/parking";
export const _urlFloors = ADMIN_URL + "/floors";
export const _urlAbout = ADMIN_URL + "/about";
export const _urlAboutSlider = ADMIN_URL + "/about-slider";
export const _urlBadges = ADMIN_URL + "/badges";
export const _urlSubscribers = ADMIN_URL + "/subscribers";
export const _urlReservations = ADMIN_URL + "/reservations";
export const _urlCallRequest = ADMIN_URL + "/call-request";
export const _urlLogs = ADMIN_URL + "/logs";

