import {request, _urlReservations, _urlCallRequest} from "../api";
import {RESERVATIONS_CONSTS} from "../constants";
import {notificationSuccess} from "../../components/uiElements/Notifications";
import {history} from "../../configs/history";
import {LIMIT_GET_RESERVATIONS} from "../../constants/constLimitCounts";


export const GetReservations = ({
                                    reset = true,
                                    offset = 0,
                                    type = undefined,
                                    limit = LIMIT_GET_RESERVATIONS,
                                    key = 'housesReservations'
                                } = {}) => {
    let url = `${_urlReservations}?offset=${offset}&limit=${limit}`;
    // type ? (url += `&type=true`) : (url += `&type=false`);
    type && (url += `&type=${type}`);
    const requestData = {
        url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                // console.log("data", data)
                dispatch({
                    type: RESERVATIONS_CONSTS.GET_RESERVATIONS,
                    payload: {
                        data: data,
                        hasMore: data.length === limit,
                        reset,
                        type,
                        key
                    }
                });
            })
    }
};

export const UpdateReservationStatus = (id, status) => {
    // console.log(id,status,"LOOOO")
    const requestData = {
        url: `${_urlReservations}/${id}`,
        token: true,
        method: "PATCH",
        data: {
            status
        }
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                notificationSuccess({
                    description: "Ամրագրումը հաջողությամբ թարմացվեց!"
                })
                history.push(`/reservations`)
            })
    }
};

export const DeleteReservationById = (id, key) => {
    const requestData = {
        url: `${_urlReservations}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: RESERVATIONS_CONSTS.DELETE_RESERVATION_BY_ID,
                    payload: {id, key}
                });
            })
            .catch(() => {
            })
    }
};

/*-------------Call---------------*/

export const GetCallStatement = ({
                                        reset = true,
                                        type =undefined,
                                        offset = 0,
                                        limit = LIMIT_GET_RESERVATIONS,
                                        key = 'callStatement'
                                    } = {}) => {
    let url = `${_urlCallRequest}?offset=${offset}&limit=${limit}`;
    type && (url += `&type=${type}`)

    const requestData = {
        url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                // console.log("data", data)
                dispatch({
                    type: RESERVATIONS_CONSTS.GET_CALL_STATEMENT,
                    payload: {
                        data: data,
                        hasMore: data.length === limit,
                        reset,
                        key,
                        type
                    }
                });
            })
    }
};

export const DeleteCallStatementById = (id) => {
    const requestData = {
        url: `${_urlCallRequest}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: RESERVATIONS_CONSTS.DELETE_CALL_STATEMENT_BY_ID,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};
