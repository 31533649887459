// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {deleteItem} from "../../utils/helperFunctions";
import {
    GetRoomTypes,
    UpdateRoomTypeById,
    DeleteRoomTypeById,
    UpdateRoomTypesPositions
} from "../../redux/actions";
import Nestable from "react-nestable";
import {NestableItem} from "../../components/cards/nestableItem";


class RoomTypes extends Component {
    constructor(props){
        super(props);
        this.deleteRoomType = deleteItem.bind(this, props.DeleteRoomTypeById, 'սենյակի տեսակը');
        this.onPositionChange = this.onPositionChange.bind(this);
    }
    componentDidMount() {
        this.props.GetRoomTypes();
    }

    getNestableItems(list, parent = null, mainLanguage){
        const items = [];
        list && !!list.length && list.forEach((item, index) => {
            const title = item?.translations?.find(tr => tr.language === mainLanguage)?.title || '';
            items.push({
                id: item.id,
                index: index,
                title: title,
                isHidden: item.isHidden,
                link: {
                    pathname: `roomTypes/edit/${item.id}`,
                },
                deleteCb:  () => this.deleteRoomType(item.id),
            })
        });
        return items;
    }

    async onPositionChange(items, item){
        const movedItemId = item.id;

        const {roomTypesList} = this.props;
        const positionedItems = items.map((item, index) => {
            return {id: item.id, position: index}
        });
        if (~roomTypesList.findIndex(m => m.id === movedItemId) &&
            ~positionedItems.findIndex(m => m.id === movedItemId)) {
            // first level room type was re-positioned in first level
            await this.props.UpdateRoomTypesPositions(positionedItems);
            this.props.GetRoomTypes();
        }
    }

    render() {
        let {roomTypesList, mainLanguage} = this.props;

        let items = this.getNestableItems(roomTypesList, null, mainLanguage);
        return <PageWrapper pageTitle={'Սենյակի տեսակներ'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/roomTypes/add"}/>
            {items && items.length !== 0 &&
            <Nestable
                items={items}
                maxDepth={1}
                onChange={this.onPositionChange}
                renderItem={NestableItem}
            />}
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetRoomTypes,
    UpdateRoomTypeById,
    DeleteRoomTypeById,
    UpdateRoomTypesPositions
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomTypes);
