export const BUILDING_CONSTS = {
    GET_BUILDINGS: "GET_BUILDINGS",
    UPDATE_BUILDING_BY_ID: "UPDATE_BUILDING_BY_ID",

    SET_FLOORS: "SET_FLOORS",
    SET_FLOOR_BY_ID: "SET_FLOOR_BY_ID",
    GET_FLOOR_BY_ID: "GET_FLOOR_BY_ID",

    GET_AREAS: "GET_AREAS",
    GET_AREA_BY_ID: "GET_AREA_BY_ID",
    GET_FLOOR_BY_BUILDING_ID: "GET_FLOOR_BY_BUILDING_ID",
    DELETE_AREA_BY_ID: "DELETE_AREA_BY_ID",
    CLEAR_AREAS_DATA: "CLEAR_AREAS_DATA",
};
