// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import Nestable from "react-nestable";
import {Slider, Switch,Checkbox} from 'antd';

// Import Styles
import '../../assets/styles/containerStyles/house-add-edit.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import MediaSelectorModal from "../../components/media/MediaSelectorModal";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    getInputValues,
    getPatchReqData,
    getPostReqData, priceWithCommas,
    toggleStateField,
} from "../../utils/helperFunctions";
import {
    AddProduct, GetAreas, UpdateProduct, CreateArea,
    UpdateArea, GetAreaById, GetFloorsByBuildingId
} from "../../redux/actions";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {AREA_TYPES, FINISHED_TYPES, MEDIA_MODAL_TYPES} from "../../constants/constType";
import {MediaFileCard} from "../../components/media/MediaFileCard";
import {MaterialIcon} from "../../components/utils/Icon";

const houseTypeOptions = Object.keys(AREA_TYPES).map(type => {
    return {
        id: AREA_TYPES[type]?.key,
        title: AREA_TYPES[type].title
    }
})

const willFinishedOptions = Object.keys(FINISHED_TYPES).map(type => {
    return {
        id: FINISHED_TYPES[type]?.key,
        title: FINISHED_TYPES[type].title
    }
})

class AddEditArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldsData: {
                area: '',
                cadastralArea: '',
                roomsCount: '',
                areaNumber: '',
                price: '',
                badge: undefined,
                type: undefined,
                willFinished: undefined,
                availableInSecondaryMarket: false,
                medias: [],
                projects: [],
                pdfs: [],
                rooms: [],
                identicalAreas: [],
                compassAngle: 0,
                bestOffer:false,
            },
            rangeVisible: false,
            floor: props?.location?.state?.floor,
            currentData: null,
            errors: {},
            errorsTabs: [],
            loading: false,
            requestLoading: false,
            languageTab: props.mainLanguage,
            isEditing: !!(props?.id && props?.location?.state?.floor),
            mediaModalOpen: false,
            mediaProjectOpen: false,
            pdfModalOpen: false,
        };
        this.compassRef = React.createRef()
        this.updatedDataMap = new Map();
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.toggleProjectModal = toggleStateField.bind(this, 'mediaProjectOpen');
        this.togglePdfModal = toggleStateField.bind(this, 'pdfModalOpen');
        this.getInputValues = getInputValues.bind(this);

        this.getPatchReqData = getPatchReqData.bind(this);
        this.getPostReqData = getPostReqData.bind(this);

        this.getMedia = this.getMedia.bind(this);
        this.onMediaPositionChange = this.onMediaPositionChange.bind(this);
        this.mediaCardWrapper = this.mediaCardWrapper.bind(this);
        this.projectCardWrapper = this.projectCardWrapper.bind(this);
        this.checkMediaListDifferent = this.checkMediaListDifferent.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.deleteProject = this.deleteProject.bind(this);
        this.getPdf = this.getPdf.bind(this);
        this.getProject = this.getProject.bind(this);
        this.deletePdf = this.deletePdf.bind(this);
        this.getRoomValues = this.getRoomValues.bind(this);
        this.addRoom = this.addRoom.bind(this);
        this.addIdenticalArea = this.addIdenticalArea.bind(this);
        this.removeRoom = this.removeRoom.bind(this);
        this.removeIdenticalArea = this.removeIdenticalArea.bind(this);
        this.validateAreaData = this.validateAreaData.bind(this);
        this.changeCompassAngle = this.changeCompassAngle.bind(this);
        // this.changeCompassAngleState = this.changeCompassAngleState.bind(this);
        this.addUpdateArea = this.addUpdateArea.bind(this);
    }

    async componentDidMount() {
        const {isEditing, floor} = this.state;
        let itemById = {}
        const {id} = this.props.match?.params;
        if (isEditing) {
            await this.props.GetFloorsByBuildingId(floor.building.id)

            await this.props.GetAreaById(id);
            itemById = this.props.houseById


            //Medias
            const medias = [];
            const pdfs = [];
            const projects = [];
            if (Array.isArray(itemById?.medias)) {
                itemById?.medias && itemById.medias.forEach(media => {
                    medias.push(media);
                })
            }
            if (Array.isArray(itemById?.pdfs)) {
                itemById?.pdfs && itemById.pdfs.forEach(pdf => {
                    pdfs.push(pdf);
                })
            }
            if (Array.isArray(itemById?.projects)) {
                itemById?.projects && itemById.projects.forEach(project => {
                    projects.push(project);
                })
            }

            let initStateData = {
                area: itemById?.area || '',
                areaNumber: itemById?.areaNumber || '',
                compassAngle: itemById?.compassAngle || '',
                price: itemById?.price || '',
                badge: itemById?.badge?.id || undefined,
                type: itemById?.type || undefined,
                willFinished: itemById?.willFinished || undefined,
                availableInSecondaryMarket: itemById?.availableInSecondaryMarket,
                project: itemById?.project || undefined,
                bestOffer:itemById?.bestOffer || false,
                medias: medias,
            }

            if (floor.number > 0) {
                initStateData = {
                    ...initStateData,
                    cadastralArea: itemById?.cadastralArea || '',
                    roomsCount: itemById?.roomsCount || '',
                    projects: projects,
                    pdfs: pdfs,
                    rooms: itemById?.rooms?.length ? itemById.rooms.map(r => {
                        return {
                            room: r.id,
                            area: r.area
                        }
                    }) : [],
                    identicalAreas: itemById?.identicalAreas?.length ? itemById?.identicalAreas.map(house => {
                        return {
                            identicalAreaFloor: house?.floor.id,
                            identicalAreaId: house.id,
                        }
                    }) : [],
                }
            }
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
        const compassImg = document.getElementById("compassImg")
        if (compassImg) {
            compassImg.style.transform = `rotate(${itemById?.compassAngle}deg)`
        }
    }

    checkMediaListDifferent(currMedias, type) {
        let result = false;
        const initMedias = this.currentData?.[type];
        if (!Array.isArray(currMedias) || (currMedias?.length !== initMedias.length)) {
            result = true;
        } else {
            currMedias.forEach((item, index) => {
                if (initMedias[index]?.id !== item?.id) {
                    result = true;
                }
            })
        }
        result ? this.updatedDataMap.set(type, currMedias?.map(m => m.id))
            : this.updatedDataMap.delete(type);
    }

    getMedia(mediaArray) {
        const {fieldsData, isEditing} = this.state;
          const medias = [...fieldsData.medias];
            mediaArray.forEach(p => {
                if (!medias.some(media => media.id === p.id)) {
                    medias.push(p);
                }
            })
        if (isEditing) {
            this.checkMediaListDifferent(mediaArray, "medias")
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                medias: mediaArray,
            },
        })
    }

    getProject(mediaArray) {
        const {fieldsData, isEditing} = this.state;

        if (isEditing) {
            this.checkMediaListDifferent(mediaArray, "projects")
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                projects: mediaArray,
            },
        })
    }

    getPdf(mediaArray) {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.checkMediaListDifferent(mediaArray, "pdfs")
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                pdfs: mediaArray,
            },
        })
    }

    deleteMedia(id) {
        const {fieldsData, isEditing} = this.state;
        let medias = fieldsData.medias.filter(m => m.id !== id);

        if (isEditing) {
            this.checkMediaListDifferent(medias, "medias")
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                medias: medias,
            }
        })
    }

    deleteProject(id) {
        const {fieldsData, isEditing} = this.state;
        let projects = fieldsData.projects.filter(m => m.id !== id);

        if (isEditing) {
            this.checkMediaListDifferent(projects, "projects")
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                projects: projects,
            }
        })
    }

    deletePdf(id) {
        const {fieldsData, isEditing} = this.state;
        let pdfs = fieldsData.pdfs.filter(m => m.id !== id);

        if (isEditing) {
            this.checkMediaListDifferent(pdfs, "pdfs")
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                pdfs: pdfs,
            }
        })
    }

    onMediaPositionChange(items, type) {

        console.log("items", items, type)
        const {fieldsData, isEditing} = this.state;
        const medias = items?.length && items.map(item => item.media);
        if (isEditing) {
            this.checkMediaListDifferent(medias, type)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                [type]: medias
            }
        })
    }

    mediaCardWrapper(data) {
        return <MediaFileCard item={data?.item?.media}
                              customDelete={this.deleteMedia}/>
    }

    projectCardWrapper(data) {
        return <MediaFileCard item={data?.item?.media}
                              customDelete={this.deleteProject}/>
    }

    addIdenticalArea() {
        const {fieldsData} = this.state;
        const newArea = {
            identicalAreaFloor: undefined,
            identicalAreaId: undefined
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                identicalAreas: [...fieldsData.identicalAreas, newArea]
            },
        })
    }

    removeIdenticalArea(position) {
        const {fieldsData} = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                identicalAreas: fieldsData.identicalAreas.filter((_, index) => position !== index)
            },
        })
        this.updatedDataMap.set('identicalAreas', position)
    }

    addRoom() {
        const {fieldsData} = this.state;
        const newRoom = {
            room: undefined,
            area: 0
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                rooms: [...fieldsData.rooms, newRoom]
            },
        })
    }

    removeRoom(position) {
        const {fieldsData} = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                rooms: fieldsData.rooms.filter((_, index) => position !== index)
            },
        })
        this.updatedDataMap.set('rooms', position)
    }

    getRoomValues({name, value, type}) {
        // console.log(type, name, value)
        const [field, position] = name.split('_')
        const {fieldsData} = this.state;

        if (name?.includes("identicalAreaFloor")) {
            this.props.GetAreas(value).then((res) => {
                console.log(res, 'res');
                this.setState({
                    fieldsData: {
                        ...fieldsData,
                        [type]: fieldsData?.[type]?.map((item, index) => index === +position ? {
                            ...item,
                            [field]: value,
                            identicalAreaId: undefined
                        } : item)
                    },
                })
            })
        } else {
            this.setState({
                fieldsData: {
                    ...fieldsData,
                    [type]: fieldsData?.[type]?.map((item, index) => index === +position ? {
                        ...item,
                        [field]: value,
                    } : item)
                },
            })
        }

    }

    validateAreaData() {
        const {fieldsData, floor} = this.state;
        const errors = {}
        const {area, roomsCount, badge, type, rooms,bestOffer, areaNumber, identicalAreas} = fieldsData;
        !area && (errors.area = true);
        ((floor?.number > 0 && (!roomsCount || !Number.isInteger(roomsCount * 2)))) && (errors.roomsCount = true);
        !areaNumber && (errors.areaNumber = true);
        !badge && (errors.badge = true);
        !type && (errors.type = true);
        if (rooms && rooms.length) {
            const roomErrors = {}
            rooms.forEach((roomItem, index) => {
                !roomItem.room && (roomErrors['room_' + index] = true);
                !roomItem.area && (roomErrors['area_' + index] = true);
            })
            Object.keys(roomErrors).length && (errors.rooms = roomErrors)
        }
        if (identicalAreas && identicalAreas.length) {
            const identicalAreaErrors = {}
            identicalAreas.forEach((houseItem, index) => {
                !houseItem.identicalAreaFloor && (identicalAreaErrors['identicalAreaFloor_' + index] = true);
                !houseItem.identicalAreaId && (identicalAreaErrors['identicalAreaId_' + index] = true);
            })
            Object.keys(identicalAreaErrors).length && (errors.identicalAreas = identicalAreaErrors)
        }
        return errors
    }

    changeCompassAngle(angle) {
        const {fieldsData} = this.state;
        this.compassRef.current.style.transform = `rotate(${angle}deg)`
        this.setState({
            fieldsData: {
                ...fieldsData,
                compassAngle: angle
            }
        })
        this.updatedDataMap.set('compassAngle', angle)
    }

    async addUpdateArea() {
        await this.setState({
            requestLoading: true
        });
        const {areas} = this.props;
        const {floor, fieldsData} = this.state;
        const errors = !this.validateAreaData()

        if (!Object.keys(errors).length) {
            this.setState({errors})
            if (!this.state.isEditing) {
                const reqData = this.getPostReqData(fieldsData);
                let position = 0;
                const items = areas
                items.forEach(h => {
                    if (h.position > position) {
                        position = h.position
                    }
                })
                reqData.position = position + 1;
                reqData.bestOffer = fieldsData.bestOffer;
                reqData.floor = floor.id
                reqData.building = floor.building.id
                reqData.project = floor.project
                reqData.compassAngle = fieldsData?.compassAngle || 0;
                fieldsData.price && (reqData.price = fieldsData?.price);
                fieldsData.cadastralArea && (reqData.cadastralArea = fieldsData?.cadastralArea);
                reqData.identicalAreas = fieldsData.identicalAreas.map(item => item?.identicalAreaId);
                reqData.areaNumber = fieldsData?.areaNumber?.toString();
                this.props.CreateArea(reqData).finally(() => {
                    this.setState({requestLoading: false})
                })

            } else {

                const reqData = this.getPatchReqData(fieldsData)
                if (floor?.number > 0) {
                    reqData.rooms = fieldsData.rooms;
                    reqData.identicalAreas = fieldsData.identicalAreas.map(item => item?.identicalAreaId);
                    reqData.areaNumber = fieldsData?.areaNumber?.toString();
                    reqData.compassAngle = fieldsData?.compassAngle || 0;
                    reqData.price = fieldsData?.price;
                    reqData.bestOffer = fieldsData.bestOffer;
                    reqData.cadastralArea = fieldsData?.cadastralArea;
                    this.props.UpdateArea(this.props.id, reqData, floor.id).finally(() => {
                        this.setState({loading: false});
                    });
                } else {
                    reqData.areaNumber = fieldsData?.areaNumber?.toString();
                    reqData.price = fieldsData?.price;
                    reqData.compassAngle = fieldsData?.compassAngle || 0;
                    this.props.UpdateArea(this.props.id, reqData, floor.id).finally(() => {
                        this.setState({loading: false});
                    });
                }

            }
        } else {
            this.setState({
                errors,
                requestLoading: false,
            })
        }
    }

    render() {
        const {
            badgesList,
            roomTypesList,
            mainLanguage,
            buildings,
            priceFormattingRegExp,
            buildingFloors,
        } = this.props;
        const {
            floor,
            fieldsData,
            isEditing,
            loading,
            errors,
            mediaModalOpen,
            mediaProjectOpen,
            pdfModalOpen
        } = this.state;
        const building = floor?.building;
        const mediaItems = [];
        const projectItems = [];
        const pdfItems = [];
        const roomTypeOptions = roomTypesList.map(type => {
            const tr = type?.translations?.find(tr => tr?.language === mainLanguage)
            return {
                id: type?.id,
                title: tr?.title || ' '
            }
        })

        const floorOptions = buildings?.find(item => item?.slug === building?.slug)?.floors?.slice(2)?.filter(item => item.number !== floor.number).map(floor => {
            return {
                id: floor.id,
                title: floor?.number || ' '
            }
        })
        const badgesOptions = badgesList.map(badge => {
            const tr = badge?.translations?.find(tr => tr?.language === mainLanguage)
            return {
                id: badge?.id,
                title: tr?.title || ' '
            }
        })
        fieldsData.medias?.length && fieldsData.medias.forEach(media => {
            mediaItems.push({
                id: media?.id,
                media: media
            })
        });
        fieldsData.projects?.length && fieldsData.projects.forEach(project => {
            projectItems.push({
                id: project?.id,
                media: project
            })
        });
        fieldsData?.pdfs?.length && fieldsData.pdfs.forEach(pdf => {
            pdfItems.push({
                id: pdf?.id,
                pdf: pdf
            })
        });

        return <PageWrapper pageTitle={`${isEditing ? 'Փոփոխել' : 'Ավելացնել'}`}>
            <section className="house-add-edit">
                <div className={'building-info'}>
                    <div className={'info-item'}>
                        <span>Մասնաշենք։ &nbsp;</span>
                        <span> {building?.slug}</span>
                    </div>
                    <div className={'info-item'}>
                        <span>Հարկ։ &nbsp;</span>
                        <span> {floor?.number}</span>
                    </div>
                </div>
                <div className="fixed-top-right">
                    <label>{fieldsData.availableInSecondaryMarket ? "Գնորդի բնակարան" : "Կազմակերպության բնակարան"}</label>
                    <Switch checked={!fieldsData.availableInSecondaryMarket}
                            onChange={(checked) => this.getInputValues({
                                name: "availableInSecondaryMarket",
                                value: !checked,
                                haveChanges: this.currentData?.availableInSecondaryMarket !== !checked
                            })}/>
                </div>
                <div className={"actions-part"}>
                    <div className={"left-action"} style={floor?.number < 0 ? {width: "100%"} : {}}>
                        <div className={'label-and-action'}>
                            <label>Նկար</label>
                            <LinkButton title="Ընտրել Մեդիադարանից"
                                        className={`media-select-btn`}
                                        cb={this.toggleMediaModal}/>
                        </div>
                        <section className={'items-wrapper media-items'}>
                            {!!mediaItems.length ? <Nestable
                                    items={mediaItems}
                                    maxDepth={1}
                                    onChange={(e) => this.onMediaPositionChange(e, "medias")}
                                    renderItem={this.mediaCardWrapper}
                                /> :
                                <p className={'empty-text'}>Նկարներ չկան</p>}
                        </section>
                    </div>
                    {floor?.number > 0 && <div className={"right-action"}>
                        <div className={'label-and-action'}>
                            <label>Կցել PDF</label>
                            <LinkButton className={"media-select-btn bg-white"}
                                        title={"Ընտրել PDF ֆայլ"}
                                        cb={this.togglePdfModal}/>
                        </div>
                        <div className={'contract-wrapper'}>
                            {!!pdfItems.length && pdfItems?.map(item => {
                                return <div className={'wrapper'}>
                                    <span>Անվանում՝ {item?.pdf?.name}</span>
                                    <MaterialIcon icon="delete" onClick={() => this.deletePdf(item?.id)}/>
                                </div>
                            })
                            }
                        </div>
                    </div>}
                    {floor?.number > 0 && <div className={'label-and-action compass-wrapper'}>
                        <label>Կողմնացույց</label>
                        <div className="image-wrapper">
                            <img id="compassImg" className="compass-img"
                                 ref={this.compassRef}
                                 src={require("../../assets/images/compass.png")}
                                 alt="" style={{transform: `rotate(${fieldsData?.compassAngle}px)`}}
                                 onLoad={() => {
                                     this.setState({rangeVisible: true})
                                 }}
                            />
                        </div>
                        {this.state.rangeVisible && <Slider onChange={this.changeCompassAngle}
                                                            max={359}
                                                            value={fieldsData?.compassAngle} tooltipVisible/>}
                    </div>}
                </div>
                {floor?.number > 0 && <div className={"left-action"}>
                    {/*<div className={'label-and-action'}>*/}
                    {/*    <label>Այլընտրանքային նախագծեր</label>*/}
                    {/*    <LinkButton title="Ընտրել Մեդիադարանից"*/}
                    {/*                className={`media-select-btn`}*/}
                    {/*                cb={this.toggleProjectModal}/>*/}
                    {/*</div>*/}
                    <section className={'items-wrapper media-items projects-items'}>
                        {!!projectItems.length ? <Nestable
                            items={projectItems}
                            maxDepth={1}
                            onChange={(e) => this.onMediaPositionChange(e, "projects")}
                            renderItem={this.projectCardWrapper}
                        /> : ""}
                    </section>
                </div>}
                <div className="info-wrapper">
                    <div className="left-part">
                        <div className={'best-offer'}>
                            <Checkbox onChange={() => this.setState({
                                          fieldsData:{
                                              ...fieldsData,
                                              bestOffer:!fieldsData?.bestOffer
                                          }
                                      })}
                                      checked={fieldsData?.bestOffer}
                                      value={fieldsData?.bestOffer}
                            >
                                <p>լավագույն առաջարկ</p>
                            </Checkbox>
                        </div>
                        <InputGroup inputType="selectCustom"
                                    label={"Տիպ"}
                                    placeholder="Տիպ"
                                    name={'type'}
                                    required={true}
                                    showSearch={false}
                                    value={fieldsData?.type}
                                    error={errors?.type}
                                    onChange={this.getInputValues}
                                    options={houseTypeOptions}/>
                        <InputGroup inputType="input"
                                    type="text"
                                    label={`Համար`}
                                    placeholder={`Համար`}
                                    name="areaNumber"
                                    value={fieldsData?.areaNumber}
                                    required={true}
                                    initValue={this.currentData?.areaNumber}
                                    error={errors?.areaNumber}
                                    onChange={this.getInputValues}/>
                        {floor?.number > 0 && <InputGroup inputType="input"
                                                          type="text"
                                                          label={"Սենյակաների քանակ"}
                                                          placeholder="Սենյակաների քանակ"
                                                          name="roomsCount"
                                                          number={true}
                                                          disabled={AREA_TYPES.PARKING.key === fieldsData.type}
                                                          value={fieldsData?.roomsCount}
                                                          initValue={this.currentData?.roomsCount}
                                                          error={errors?.roomsCount}
                                                          onChange={this.getInputValues}/>}
                        <InputGroup inputType="input"
                                    type="text"
                                    label={"Գին"}
                                    placeholder="Գին"
                                    name="price"
                            // integerNumber={true}
                                    value={priceWithCommas(priceFormattingRegExp, fieldsData?.price)}
                                    required={false}
                                    initValue={priceWithCommas(priceFormattingRegExp, this.currentData?.price)}
                                    error={errors?.price}
                                    onChange={this.getInputValues}/>
                    </div>
                    <div className="right-part">
                        <InputGroup inputType="input"
                                    type="text"
                                    label={"Ընդհանուր մակերես"}
                                    placeholder="Ընդհանուր մակերես"
                                    name="area"
                                    number={true}
                                    value={fieldsData?.area}
                                    required={true}
                                    initValue={this.currentData?.area}
                                    error={errors?.area}
                                    onChange={this.getInputValues}/>
                        {floor?.number > 0 && <InputGroup inputType="input"
                                                          type="text"
                                                          label={"Մոտավոր կադաստրային մակերես"}
                                                          placeholder="Մոտավոր կադաստրային մակերես"
                                                          name="cadastralArea"
                                                          number={true}
                                                          value={fieldsData?.cadastralArea}
                                                          initValue={this.currentData?.cadastralArea}
                                                          error={errors?.cadastralArea}
                                                          onChange={this.getInputValues}/>}
                        <InputGroup inputType="selectCustom"
                                    label={"Պիտակ"}
                                    placeholder={"Պիտակ"}
                                    name="badge"
                                    required={true}
                                    showSearch={false}
                                    value={fieldsData?.badge}
                                    error={errors?.badge}
                                    initValue={this.currentData?.badge}
                                    onChange={this.getInputValues}
                                    options={badgesOptions}/>
                        <InputGroup inputType="selectCustom"
                                    label={"Ավարտ"}
                                    placeholder="Ավարտ"
                                    name={'willFinished'}
                                    showSearch={false}
                                    value={fieldsData?.willFinished}
                                    error={errors?.willFinished}
                                    onChange={this.getInputValues}
                                    options={willFinishedOptions}/>
                    </div>
                </div>
                {floor?.number > 0 && <>
                    <div className={'rooms'}>
                        <label className={'block-label'}>Ավելացնել սենյակ</label>
                        {fieldsData?.rooms?.map((roomItem, index) => {
                            return <div className={'room-wrapper'} key={index}>
                                <InputGroup inputType="selectCustom"
                                            label={"Սենյակի տեսակ"}
                                            placeholder="Սենյակի տեսակ"
                                            name={'room_' + index}
                                            required={true}
                                            showSearch={false}
                                            value={roomItem?.room}
                                            error={errors?.rooms?.['room_' + index]}
                                            onChange={(e) => this.getRoomValues({
                                                name: e?.name,
                                                value: e?.value,
                                                type: "rooms"
                                            })}
                                            options={roomTypeOptions}/>
                                <InputGroup inputType="input"
                                            type="text"
                                            label={"Մակերես"}
                                            placeholder="Մակերես"
                                            name={"area_" + index}
                                            number={true}
                                            required={true}
                                            value={roomItem?.area}
                                            error={errors?.rooms?.['area_' + index]}
                                            onChange={(e) => this.getRoomValues({
                                                name: e?.name,
                                                value: e?.value,
                                                type: "rooms"
                                            })}/>
                                <LinkButton title={<MaterialIcon icon={'remove'}/>} className={'bg-red'}
                                            cb={this.removeRoom.bind(this, index)}/>
                            </div>
                        })}
                        <LinkButton disabled={AREA_TYPES.PARKING.key === fieldsData.type} title={<MaterialIcon icon={'add'}/>} className={'bg-green'} cb={this.addRoom}/>
                    </div>
                    <div className={'rooms'}>
                        <label className={'block-label'}>Ավելացնել նմանատիպ բնակարան</label>
                        {fieldsData?.identicalAreas?.map((houseItem, index) => {
                            return <div className={'room-wrapper'} key={index}>
                                <InputGroup inputType="selectCustom"
                                            label={"Հարկ"}
                                            placeholder="Հարկ"
                                            name={'identicalAreaFloor_' + index}
                                            required={true}
                                            showSearch={false}
                                            value={houseItem?.identicalAreaFloor}
                                            error={errors?.identicalAreas?.['identicalAreaFloor_' + index]}
                                            onChange={(e) => this.getRoomValues({
                                                name: e?.name,
                                                value: e?.value,
                                                type: "identicalAreas"
                                            })}
                                            options={floorOptions}/>
                                <InputGroup inputType="selectCustom"
                                            label={"Տան համար"}
                                            placeholder="Տան համար"
                                            name={"identicalAreaId_" + index}
                                            disabled={!houseItem?.identicalAreaFloor}
                                            number={true}
                                            required={true}
                                            value={houseItem?.identicalAreaId}
                                            error={errors?.identicalAreas?.['identicalAreaId_' + index]}
                                            onChange={(e) => this.getRoomValues({
                                                name: e?.name,
                                                value: e?.value,
                                                type: "identicalAreas"
                                            })}
                                            options={buildingFloors.find(item => item?.id === houseItem?.identicalAreaFloor)?.areas.map(item => {
                                                return {
                                                    id: item?.id,
                                                    title: item.areaNumber || ' '
                                                }
                                            })}
                                />
                                <LinkButton title={<MaterialIcon icon={'remove'}/>} className={'bg-red'}
                                            cb={this.removeIdenticalArea.bind(this, index)}/>
                            </div>
                        })}
                        <LinkButton title={<MaterialIcon icon={'add'}/>} className={'bg-green'}
                                    disabled={AREA_TYPES.PARKING.key === fieldsData.type}
                                    cb={this.addIdenticalArea}/>
                    </div>
                </>}
                <div className="flex-wrapper-right">
                    <LinkButton title={`${isEditing ? 'Փոփոխել' : 'Ավելացնել'}`}
                                loading={loading}
                        // disabled={!this.updatedDataMap.size && isEditing}
                                cb={this.addUpdateArea}/>
                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                mediaType={MEDIA_MODAL_TYPES.MEDIA_ARRAY}
                getMedia={this.getMedia}
                multiSelect={true}
                appendMedias={fieldsData.medias}
                closeModal={this.toggleMediaModal}
            />
            <MediaSelectorModal
                isOpen={!!mediaProjectOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                mediaType={MEDIA_MODAL_TYPES.MEDIA_ARRAY}
                getMedia={this.getProject}
                appendMedias={fieldsData.projects}
                closeModal={this.toggleProjectModal}
            />
            <MediaSelectorModal
                isOpen={!!pdfModalOpen}
                acceptTypes={['pdf']}
                mediaType={MEDIA_MODAL_TYPES.MEDIA_ARRAY}
                getMedia={this.getPdf}
                appendMedias={fieldsData.pdfs}
                closeModal={this.togglePdfModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
        AddProduct,
        UpdateProduct,
        GetAreas,
        CreateArea,
        UpdateArea,
        GetAreaById,
        GetFloorsByBuildingId
    };

export default connect(mapStateToProps, mapDispatchToProps)(AddEditArea)
