// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//import styles
import '../../assets/styles/containerStyles/about.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
// Import utils
import {
    changeStateField,
    getInputValues, getPatchReqData,
    getTranslatableInputValues, getTranslationData,
    initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {DeleteProjectBlock, GetProjectById, UpdateProjectData} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {Editor} from "../../components/uiElements/Editor";
import {generateFileMediaUrl} from "../../utils/generateMemberMediaUrl";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import swal from "sweetalert";
import ItemsList from "../../components/ItemsList";

class UpdateProject extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
            'detail',
            'address',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                mediaMain: undefined,
                latitude: "",
                longitude: "",
            },
            errors: {},
            errorsTabs: [],
            isEditing: true,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.setProjectData = this.setProjectData.bind(this);
        this.updateProject = this.updateProject.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.getNestableItems = this.getNestableItems.bind(this);
    }

    async componentDidMount() {
        await this.props.GetProjectById(this.props.id)
        const {projectById} = this.props;
        this.setProjectData(projectById)
    }

    setProjectData(projectById) {
        const {fieldsData} = this.state;
        const translationsData = {};

        if (projectById) {
            Object.keys(fieldsData.translations).forEach(key => {
                const trItem = projectById.translations.find(item => item.language === key);
                translationsData[key] = {
                    ...fieldsData.translations[key],
                    address: trItem?.address || '',
                    title: trItem?.title || '',
                    description: trItem?.description || '',
                    detail: trItem?.detail || '',
                };
            });
        }
        const initStateData = {
            translations: translationsData,
            mediaMain: projectById?.mediaMain,
            latitude: projectById?.latitude || '',
            longitude: projectById?.longitude || '',
        }
        this.currentData = JSON.parse(JSON.stringify(initStateData));
        this.setState({
            fieldsData: initStateData,
            errors: {},
            errorsTabs: [],
            requestLoading: false,
        });
    }

    deleteBlock(removingId) {
        swal({
            title: "Զգուշացում!",
            text: `Ցանկանում եք ջնջել բաժինը?`,
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                this.props.DeleteProjectBlock(removingId, this.props.id).then(() => {
                    // this.setState({
                    //     ...this.state,
                    //     serviceData: {
                    //         ...this.state.serviceData,
                    //         blocks: this.state.serviceData?.blocks.filter(block => block.id !== removingId) || [],
                    //     },
                    // })
                })
            }
        });
    }

    getNestableItems(itemsList) {
        const {mainLanguage, projectById} = this.props;
        let items = [];
        itemsList?.length && itemsList.forEach((item, index) => {
            const {id, translations} = item;
            const mainTranslations = translations?.find(item => item.language === mainLanguage);
            const {title} = mainTranslations || {};

            items.push({
                id,
                index,
                title,
                withImage: true,
                largeItem: true,
                path: item?.mediaArray?.[0]?.path,
                link: {
                    pathname: `/dashboard/projects/${this.props.id}/edit-block/${id}`,
                    state: {
                        projectById,
                    }
                },
                url: item.url,
                deleteCb: () => this.deleteBlock(item.id),
            })
        });
        return items;
    }

    getListInfo(data) {
        let {mainLanguage} = this.props;
        return !!data?.length && data?.map((item) => {
            const {id, translations} = item;
            const mainTranslations = translations?.find(item => item.language === mainLanguage);
            const {title} = mainTranslations || {};
            return {
                id: id,
                values: [
                    title ?? '-',
                ],
                deleteCb: () => this.deleteBlock(item.id)
            }
        });
    }

    getMedia(mediaArray){
        const { fieldsData, errors, isEditing } = this.state;
        if ( isEditing ) {
            this.currentData?.mediaMain?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('mediaMain', mediaArray[0]?.id)
                : this.updatedDataMap.delete('mediaMain');
        }

        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: mediaArray[0],
            },
            errors: {
                ...errors,
                mediaMain: false
            }
        })
    }

    async updateProject() {
        await this.setState({
            requestLoading: true
        });
        const {translations, mediaMain} = this.state.fieldsData;
        const translationRequiredFields = ['address', 'title', 'description', 'detail']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if (!mediaMain) {
            errors.mediaMain = true;
            result = false;
        }
        if (result) {
            const trData = getTranslationData(translations);
            const reqData = this.getPatchReqData(trData);
            this.props.UpdateProjectData(reqData, this.props.id).then((updatedData) => {
                this.updatedDataMap.clear();
                this.setProjectData(updatedData)
            }).catch(() => {
            }).finally(() => {
                this.setState({requestLoading: false});
            });

        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render() {
        const {projectById, id} = this.props;
        const {fieldsData, errors, languageTab, requestLoading, isEditing, errorsTabs, mediaModalOpen} = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        const itemListHeaderInfo = {
            generalInfo: ["Անվանում"],
            largeItems: [0],
            actions: ["edit", "delete"],
        };

        const blocksListInfo = this.getListInfo(projectById?.blocks);

        return <PageWrapper pageTitle={'Մեր Մասին'}>
            <section className="about">
                <div className="top-side">
                    <label>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}/>
                </div>
                {fieldsData?.mediaMain && <div className={'background-image'}>
                    <img src={generateFileMediaUrl(fieldsData?.mediaMain?.path)} alt={''}/>
                </div>}
                <div className="info-wrapper">
                        <InputGroup inputType="input"
                                    type="text"
                                    label={"Լայնություն"}
                                    placeholder="Լայնություն"
                                    name="latitude"
                                    value={fieldsData?.latitude}
                                    required={true}
                                    initValue={this.currentData?.latitude}
                                    error={errors?.latitude}
                                    onChange={this.getInputValues}
                                    maxLength={20}/>

                        <InputGroup inputType="input"
                                    type="text"
                                    label={"Երկայնություն"}
                                    placeholder="Երկայնություն"
                                    name="longitude"
                                    value={fieldsData?.longitude}
                                    required={true}
                                    initValue={this.currentData?.longitude}
                                    error={errors?.longitude}
                                    onChange={this.getInputValues}
                                    maxLength={20}/>

                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={errorsTabs}
                                  activeTab={languageTab}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Անվանում"
                                placeholder="Անվանում"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Հասցե"
                                placeholder="Հասցե"
                                name="address"
                                value={currentData.address}
                                initValue={initData.address}
                                error={errors['address' + languageTab]}
                                maxLength={100}
                        // required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="textarea"
                                type="text"
                                label="Նկարագրություն"
                                placeholder="Նկարագրություն"
                                name="description"
                                value={currentData.description}
                                initValue={initData.description}
                                error={errors['description' + languageTab]}
                                maxLength={100}
                        // required={true}
                                onChange={this.getTranslationValues}/>
                    <div className="editor-wrapper">
                        <label>Մանրամասներ {/*<span className={'required-badge'}>*</span>*/}</label>
                        <Editor value={currentData?.detail || ''}
                                name={'detail'}
                                initValue={initData?.detail}
                                error={errors['detail' + languageTab]}
                                onChange={this.getTranslationValues}/>
                    </div>
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.updateProject}/>
                </div>
                <div className="block-wrapper">
                    <LinkButton title={`Ավելացնել բաժին`}
                                loading={requestLoading}
                                link={`/dashboard/projects/${id}/add-block`}
                    />
                    {
                        !!projectById?.blocks?.length && <>
                            <label className={'list-label'}>Բաժիններ</label>
                            <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                                       itemListInfo={blocksListInfo}
                                       loading={requestLoading}
                                       editLink={`/dashboard/projects/${this.props.id}/edit-block`}/>
                        </>
                    }

                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetProjectById,
    UpdateProjectData,
    DeleteProjectBlock
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProject)
