// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import styles
import '../../assets/styles/containerStyles/moderators.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import PageHeader from "../../components/pageContentViews/pageHeader";
import ItemsList from "../../components/ItemsList";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetModerators, DeleteModerator} from "../../redux/actions";
import { deleteItem } from "../../utils/helperFunctions";

class Moderators extends Component {
    constructor (props) {
        super(props);
        this.deleteModerator = deleteItem.bind(this, props.DeleteModerator, 'մոդերատորին');
    }

    // componentDidMount() {
        // this.props.GetModerators();
    // }

    render() {
        const {moderatorsList, requestLoading} = this.props;
        let itemListHeaderInfo = {
            generalInfo: ["Անուն", "Էլ․ հասցե",],
            largeItems: [1],
            status: true,
            actions: ["edit", 'delete'],
        };
        let itemListInfo = moderatorsList?.map(moderator => {
            const {id, username, firstName, lastName, isEnabled,} = moderator;
            return {
                id: id,
                values: [`${firstName} ${lastName}`, username],
                status: isEnabled,
                deleteCb: this.deleteModerator.bind(this, id)
            };
        });
        return <PageWrapper pageTitle={'Մոդերատորներ'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/moderators/add"}
                        renderingItemsTitleWithCount={`Մոդերատորների քանակ ${moderatorsList?.length}`}/>
            <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                       itemListInfo={itemListInfo}
                       loading={requestLoading}
                       editLink={"/moderators/edit"}/>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetModerators,
    DeleteModerator
};

export default connect(mapStateToProps, mapDispatchToProps)(Moderators)
