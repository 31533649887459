//media modal
export const MEDIA_MODAL_TYPES = {
    MEDIA_MAIN: 'MEDIA_MAIN',
    MEDIA_ARRAY: 'MEDIA_ARRAY',
};

export const MEDIA_TYPES = {
    FILE: 'FILE',
    FOLDER: 'FOLDER',
};

const STATUS_COLORS = {
    NEW: '#FF9112',
    CANCELLED: '#828B99',
    APPROVED: '#13C712',
    REJECTED: '#FF1212',
};
export const RESERVATION_STATUS_TYPES = {
    PENDING: {
        color: '#68beff',
        name: 'Ընթացիկ',
        key: 'PENDING',
    },
    ACCEPTED: {
        color: '#13C712',
        name: 'Հաստատված',
        key: 'ACCEPTED',
    },
    REJECTED: {
        color: '#FF1212',
        name: 'Մերժված',
        key: 'REJECTED',
    },
};
export const REVIEW_STATUS_TYPES = {
    INITIAL: {
        key: 'INITIAL',
        title: 'Նոր',
        color: STATUS_COLORS.NEW
    },
    APPROVED: {
        key: 'APPROVED',
        title: 'Հաստատված',
        color: STATUS_COLORS.APPROVED
    },
    REJECTED: {
        key: 'REJECTED',
        title: 'Մերժված',
        color: STATUS_COLORS.REJECTED
    },
};


export const STATUS_LIST = {
    NEW: {
        title: "Հաստատել",
        key: '5ee8dd7c4ac0e93f4336fcda',
        color: STATUS_COLORS.NEW,
    },
    CANCELLED: {
        title: 'Չեղարկված',
        key: '5ee8dda24ac0e93f4336fcdd',
        color: STATUS_COLORS.CANCELLED,
    },
    APPROVED: {
        title: 'Հաստատված',
        key: '5ee8dd984ac0e93f4336fcdb',
        color: STATUS_COLORS.APPROVED,
    },
    REJECTED: {
        title: 'Մերժված',
        key: '5ee8dda24ac0e93f4336fcdc',
        color: STATUS_COLORS.REJECTED,
    },
};
export const MEASUREMENT_TYPES = {
    LITER: 'Լ',
    KILOGRAM: 'Կգ',
    PIECES: 'Հատ',
    BOX: 'Արկղ',
};

export const UPLOAD_TYPES = {
    SELF: 'SELF',
    URL: 'URL',
};

export const SLIDER_RESOURCE_TYPES = {
    PRODUCT: {
        title: 'Շենքեր',
        key: 'PRODUCT',
    },
    SECTION: {
        title: 'Բաժին',
        key: 'SECTION',
    },
    BANNER:{
        title: 'Մինի-Մարկետ',
        key: 'BANNER',
    },
};

export const AREA_TYPES = {
    APARTMENT: {
        title: 'Բնակարան',
        key: 'APARTMENT',
    },
    OFFICE: {
        title: 'Գրասենյակային տարածք',
        key: 'OFFICE',
    },
    HOTEL:{
        title: 'Հյուրանոց',
        key: 'HOTEL',
    },
    PARKING:{
        title: 'Ավտոկայանատեղի',
        key: 'PARKING',
    },
};
export const FINISHED_TYPES = {
    SOON: {
        title: 'Շուտով',
        key: 'SOON',
    },
    COMPLETED: {
        title: 'Ավարտված',
        key: 'COMPLETED',
    },
    '2022':{
        title: '2022',
        key: '2022',
    },
    '2023':{
        title: '2023',
        key: '2023',
    },
};

export const DEFAULT_IMAGE_ID = '5db99765e2019423d6e0df80';