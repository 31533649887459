// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Switch} from "antd";

// Import components
import {Editor} from "../../components/uiElements/Editor";
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {MediaFileCard} from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField,
    getInputValues,
    getPatchReqData,
    getTranslatableInputValues,
    getTranslationData,
    initTranslations,
    toggleStateField,
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {CreateCustomPage, UpdateCustomPageById} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";


class AddEditCustomPages extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'metaTitle',
            'metaDescription',
            'content',
            'pageUrl',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                mediaMain: undefined,
                isHidden: false,
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
            mediaModalOpen: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.addEditCustomPage = this.addEditCustomPage.bind(this);
    }

    async componentDidMount() {
        const {isEditing} = this.state;
        const translationsData = {};

        if (isEditing) {
            const editingData = this.props?.customPagesList?.find(page => page.id === this.props.id);
            if (editingData) {
                editingData.translations.forEach(item => {
                    translationsData[item.language] = {
                        ...translationsData[item.language],
                        title: item.title || '',
                        metaTitle: item.metaTitle || '',
                        metaDescription: item.metaDescription || '',
                        content: item.content || '',
                        pageUrl: item.pageUrl || '',
                    };
                });
            }

            const initStateData = {
                translations: translationsData,
                mediaMain: editingData?.mediaMain,
                isHidden: editingData?.isHidden,
            };
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                ...this.state,
                fieldsData: initStateData
            });

        }
    }

    getMedia(mediaArray) {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.mediaMain?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('mediaMain', mediaArray[0]?.id)
                : this.updatedDataMap.delete('mediaMain');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: mediaArray[0],
            },
        })
    }

    deleteMedia() {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {

            this.currentData.mediaMain ?
                this.updatedDataMap.set("mediaMain", null) :
                this.updatedDataMap.delete("mediaMain");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: null
            }
        })
    }

    async addEditCustomPage() {
        await this.setState({
            requestLoading: true
        });
        const {translations, mediaMain, isHidden } = this.state.fieldsData;
        const translationRequiredFields = ['pageUrl'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};
        if (result) {
            const trData = getTranslationData(translations);
            if (!this.state.isEditing) {
                const reqData = {
                    isHidden,
                    translations: trData,
                };
                mediaMain && (reqData.mediaMain = mediaMain.id);
                this.props.CreateCustomPage(reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    })
            } else {
                const reqData = this.getPatchReqData(trData, ['mediaMain', 'isHidden']);
                this.props.UpdateCustomPageById(this.props.id, reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    });
            }

        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render() {
        const {
            fieldsData, errors, languageTab, requestLoading, isEditing, errorsTabs, mediaModalOpen,
        } = this.state;
        const {translations} = fieldsData;
        const trData = translations[languageTab] || {};
        const initTrData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Էջ' : 'Փոփոխել Էջը'}>
            <section className="general-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>

                <div className="top-side">
                    <label>Նկար</label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData.mediaMain && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.mediaMain}
                                   customDelete={this.deleteMedia}
                    />
                </div>}

                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>

                <InputGroup inputType="input"
                            type="text"
                            label="Անվանում"
                            placeholder="Անվանում"
                            name="title"
                            value={currentData.title}
                            initValue={initTrData.title}
                            error={errors['title' + languageTab]}
                            maxLength={256}
                            onChange={this.getTranslationValues}/>

                <InputGroup inputType="input"
                            type="text"
                            label="Էջի հղում"
                            placeholder="Էջի հղում"
                            name="pageUrl"
                            value={currentData.pageUrl}
                            initValue={initTrData.pageUrl}
                            error={errors['pageUrl' + languageTab]}
                            maxLength={1000}
                            required={true}
                            onChange={this.getTranslationValues}/>

                <div className={'editor-wrapper'}>
                    <label>Էջի պարունակություն</label>
                    <Editor value={trData.content || ""}
                            name={'content'}
                            initValue={initTrData?.content}
                            error={errors['content' + languageTab]}
                            onChange={this.getTranslationValues}/>
                </div>

                <InputGroup inputType="input"
                            type="text"
                            label="Մետա Անվանում"
                            placeholder="Մետա Անվանում"
                            name="metaTitle"
                            value={currentData.metaTitle}
                            initValue={initTrData.metaTitle}
                            error={errors['metaTitle' + languageTab]}
                            maxLength={100}
                            onChange={this.getTranslationValues}/>

                <InputGroup inputType="input"
                            type="text"
                            label="Մետա Նկարագրություն"
                            placeholder="Մետա Նկարագրություն"
                            name="metaDescription"
                            value={currentData.metaDescription}
                            initValue={initTrData.metaDescription}
                            error={errors['metaDescription' + languageTab]}
                            maxLength={1000}
                            onChange={this.getTranslationValues}/>

                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditCustomPage}/>
                </div>
            </section>

            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />

        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateCustomPage,
    UpdateCustomPageById,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditCustomPages)
