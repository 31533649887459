// Import packages
import React from "react";
import {NavLink} from "react-router-dom";

// Import styles
import "../../assets/styles/navbars/leftSideMenu.scss";

// Import components
import {MaterialIcon} from "../utils/Icon";

//import utils
import {ACCESS_PAGES} from "../../constants/accessPages";

export function LeftSideMenu(props) {
    const {
        leftSideMenuOpen, accessPages, isAdmin, notSeenReviewsCount, notSeenOrdersCount
    } = props;
    return <aside className={`left-side-menu custom-scrollbar-y ${leftSideMenuOpen ? "open" : ""}`}>
        <ul className="aside-menu-list">
            <li>
                <NavLink to={`/dashboard`} activeClassName='active'>
                    <MaterialIcon icon="dashboard"/>
                    <span>Նախագիծ</span>
                </NavLink>
            </li>
            {props.isAdmin && <li>
                <NavLink to="/moderators" activeClassName='active'>
                    <MaterialIcon icon="supervised_user_circle"/>
                    <span>Մոդերատորներ</span>
                </NavLink>
            </li>}
            {
                Object.keys(ACCESS_PAGES).map(path => {
                    const page = ACCESS_PAGES?.[path];

                    const haveAccess = isAdmin || accessPages?.includes(page?.key);
                    return haveAccess && !page?.isHidden ? <li key={path}>

                        <NavLink to={`/${path}`} activeClassName='active'>
                            <MaterialIcon icon={page?.icon}/>
                            <span>{page?.name}</span>
                            {path === 'reviews' && !!notSeenReviewsCount &&
                            <span className={'badge'}>{notSeenReviewsCount > 99 ? '99+' : notSeenReviewsCount}</span>}
                            {path === 'orders' && !!notSeenOrdersCount &&
                            <span className={'badge'}>{notSeenOrdersCount > 99 ? '99+' : notSeenOrdersCount}</span>}
                        </NavLink>
                    </li> : null
                })
            }
        </ul>

    </aside>
}
