// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

// Import Styles
import '../../assets/styles/containerStyles/buildings.scss';

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import { Link } from "react-router-dom";
// Import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import { GetBuildings } from "../../redux/actions";


class Buildings extends Component {

    componentDidMount () {
        this.props.GetBuildings(this.props.id)
    }

    render () {
        const { buildings, mainLanguage } = this.props;

        return <PageWrapper pageTitle={'Շենքեր'}>
            <section className="buildings">
                <div className={'projects'}>
                    {buildings.map(building => {
                        const address = building.translations?.find(tr => tr.language === mainLanguage)?.address || '';
                        return <Link className={`building `}
                                     to={`/dashboard/floors/${building.id}`}
                                     key={building.id}
                        >
                            <div className={'building-top-side'}>
                                <div className={'building-logo-wrapper'}>
                                    <span>{building?.slug}</span>
                                </div>
                                <div className={'top-side-info-wrapper'}>
                                    <div className={'info info-bold'}>
                                        <span>Մասնաշենք</span>
                                    </div>
                                    <div className={'info info-grey'}>
                                        <span> {address}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={'building-bottom-side'}>
                                <div className={'info'}>
                                    <span>Հարկերի քանակ։ &nbsp;</span>
                                    <span> {building?.floors?.length}</span>
                                </div>
                                <div className={'info'}>
                                    <span>Բնակարանների քանակ։ &nbsp;</span>
                                    <span> {building?.housesCount}</span>
                                </div>
                                <div className={'info'}>
                                    <span>Ազատ բնակարանների քանակ։ &nbsp;</span>
                                    <span> {building?.availableHousesCount}</span>
                                </div>
                                <div className={'info'}>
                                    <span>Զբաղված բնակարանների քանակ։ &nbsp;</span>
                                    <span> {building?.soldHousesCount}</span>
                                </div>
                            </div>
                        </Link>
                    })}
                </div>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetBuildings
};

export default connect(mapStateToProps, mapDispatchToProps)(Buildings)
