// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

// Import Styles
import '../assets/styles/containerStyles/buildings.scss';


// Import components
import PageWrapper from '../components/pageContentViews/pageWrapper';

// Import utils
import { mapStateToProps } from "../redux/mapStateToProps";
import { GetProjects } from "../redux/actions";
import ItemsList from "../components/ItemsList";


class Dashboard extends Component {

    componentDidMount () {
        this.props.GetProjects()
    }

    render () {
        const { projects } = this.props;

        const itemListHeaderInfo = {
            generalInfo: ["Անվանում", "Հասցե"],
            largeItems: [0],
            actions: ['details','edit'],
        };

        const itemListInfo = projects?.map((item) => {
            const { id, title, address, available} = item;
            return {
                id,
                values: [
                    title || '-',
                    address || '-'
                ],
                isAvailable: available
            }
        });

        return <PageWrapper pageTitle={'Նախագծեր'}>
            <section className="dashboard">
                <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                           itemListInfo={itemListInfo}
                           detailsLink={`dashboard/projects`}
                           editLink={"/dashboard/projects/edit"}/>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetProjects
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
