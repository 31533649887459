import React from "react";

import "../assets/styles/containerStyles/footer.scss";

export function Footer(props) {


    return <div className={`implementation ${props.leftSideMenuOpen ? "show" : ""}`}>
        <div className={'inner'}>
            <span>Իրագործումը՝ </span>
            <a href="https://4steps.am">
                <img src={require("../assets/images/4steps.svg")} alt="icon"/>
            </a>
        </div>
    </div>
}
