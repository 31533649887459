// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import styles
import './block-add-edit.scss';


// Import components
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {MediaFileCard} from "../../components/media/MediaFileCard";
import {Editor} from "../../components/uiElements/Editor";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {CreateProjectBlock, UpdateProjectBlock} from "../../redux/actions";
import {
    changeStateField,
    getInputValues,
    getPatchReqData, getPostReqData, getTranslatableInputValues, getTranslationData,
    initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {validateTranslations} from "../../utils/validateTranslations";


class AddEditBlock extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                mediaMain: undefined,
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getPostReqData = getPostReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.updateItem = this.updateItem.bind(this);

    }

    async componentDidMount() {
        const {isEditing, fieldsData} = this.state;
        const {projects, match, projectById} = this.props;
        const {project, id} = match?.params || {}
        if (isEditing) {
            if (!project || !id || !projects) {
                return;
            }
            const translationsData = {};
            console.log(projectById, 'location?.state');
            const editingData = projectById?.blocks?.find(item => item.id === id);
            if (editingData) {
                Object.keys(fieldsData.translations).forEach(key => {
                    const trItem = editingData.translations.find(item => item.language === key);
                    translationsData[key] = {
                        ...fieldsData.translations[key],
                        title: trItem?.title || '',
                        description: trItem?.description || '',
                    };
                });
            }
            const initStateData = {
                translations: translationsData,
                mediaMain: editingData?.mediaMain,
            }
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }

    getMedia(mediaArray) {
        const {fieldsData, errors, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.mediaMain?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('mediaMain', mediaArray[0]?.id)
                : this.updatedDataMap.delete('mediaMain');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: mediaArray[0],
            },
            errors: {
                ...errors,
                mediaMain: false
            }
        })
    }

    async updateItem() {
        const {isEditing, fieldsData} = this.state;
        const {match, projectById} = this.props;
        const {id, project} = match?.params || {}
        await this.setState({
            requestLoading: true
        });
        const {translations, mediaMain} = fieldsData;
        const translationRequiredFields = ['title']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if (!mediaMain) {
            errors.mediaMain = true;
            result = false;
        }
        if (result) {
            if (!isEditing) {
                const reqData = {
                    mediaMain: mediaMain.id,
                    position: projectById?.blocks?.[projectById?.blocks?.length - 1]?.position + 1 || 0,
                    translations: getTranslationData(translations)
                };
                this.props.CreateProjectBlock(reqData, project)
            } else {
                const trData = getTranslationData(translations);
                const reqData = this.getPatchReqData(trData);
                this.props.UpdateProjectBlock(id, reqData, project)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render() {
        const {fieldsData, errors, languageTab, mediaModalOpen, requestLoading, isEditing, errorsTabs} = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Բաժին' : 'Փոփոխել Բաժինը'}>
            <section className="slider-add-edit">

                <div className="top-side">
                    <label>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData?.mediaMain && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.mediaMain}
                                   customDelete={this.deleteMedia}
                    />
                </div>}


                <div className="info-wrapper">
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={errorsTabs}
                                  activeTab={languageTab}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Անվանում"
                                placeholder="Անվանում"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>

                    <div className="editor-wrapper">
                        <label>Ենթավերնագիր <span className={'required-badge'}>*</span></label>
                        <Editor value={currentData?.description || ''}
                                name={'description'}
                                initValue={initData?.description}
                                error={errors['description' + languageTab]}
                                onChange={this.getTranslationValues}/>
                    </div>
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.updateItem}/>
                </div>

            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateProjectBlock,
    UpdateProjectBlock
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditBlock);