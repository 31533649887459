// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import styles
import '../../assets/styles/media/add-file-dropDown-card.scss';
import "../../assets/styles/media/media-selector-modal.scss";
import iconComputer from '../../assets/images/ic_choose_from_computer.svg';
import iconSearch from '../../assets/images/ic_search.svg';
import iconList from '../../assets/images/file-manager/ic_list.svg';
import iconGrid from '../../assets/images/file-manager/ic_grid.svg';
import iconUrl from '../../assets/images/ic_choose_by_url.svg';
import iconFile from '../../assets/images/ic_file.svg';
import iconNewFile from '../../assets/images/file-manager/new_file.svg';
import iconCloseDark from '../../assets/images/ic_choose_by_url.svg';

// Import Components
import {Dropdown, Modal} from "antd";
import {MediaFileCard} from "./MediaFileCard";
import {MediaFolderCard} from "./MediaFolderCard";
import {LinkButton} from "../buttons/buttons";
import {InputGroup} from "../uiElements/inputGroup";
import AddMediaFolder from "./AddFolder";
import MediaUploader from "./AddMedia/MediaUploader";
import InfiniteScroll from "react-infinite-scroller";

// Import utils
import {GetMedia, setFolderList, toggleMediaView, UploadFile} from "../../redux/actions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    addFile,
    cancelAddFileByUrl,
    closeAddMedia,
    chooseUploadType,
    getFileUploadByUrlValues,
    getMedias,
    onDropFile,
    onUploadClick,
    onUploadProgress,
    toggleAddFileCard,
    triggerFileUpload,
    uploadMedias, addLogoToImage,
} from "./AddMedia/functions/action-functions";
import {MEDIA_MODAL_TYPES, UPLOAD_TYPES} from "../../constants/constType";


class MediaSelectorModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedImages: [],
            searchValue: '',
            fetching: false,

            uploading: false,
            currentUploadingId: null,

            addingFiles: [],
            filesIsChoose: false,
            //new
            loadingGetFileByUrl: false,
            addFileCardOpen: false,
            chooseType: null,
            name: '',
            url: '',
            errors: {},
            fileByUrl: null,
        };
        this.selectImage = this.selectImage.bind(this);
        this.insertWithDoubleClick = this.insertWithDoubleClick.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.insertMedia = this.insertMedia.bind(this);
        this.openFolder = this.openFolder.bind(this);
        this.getSearchValue = this.getSearchValue.bind(this);
        this.search = this.search.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this,);
        this.getFiles = this.getFiles.bind(this,);

        this.inputElement = React.createRef();
        this.onUploadClick = onUploadClick.bind(this);
        this.triggerFileUpload = triggerFileUpload.bind(this);
        this.getMedias = getMedias.bind(this);
        this.onDropFile = onDropFile.bind(this);
        this.uploadMedias = uploadMedias.bind(this);
        this.closeAddMedia = closeAddMedia.bind(this);
        this.onUploadProgress = onUploadProgress.bind(this);
        //new
        this.getFileUploadByUrlValues = getFileUploadByUrlValues.bind(this);
        this.chooseUploadType = chooseUploadType.bind(this);
        this.toggleAddFileCard = toggleAddFileCard.bind(this);
        this.cancelAddFileByUrl = cancelAddFileByUrl.bind(this);
        this.addFile = addFile.bind(this);
        this.addLogoToImage = addLogoToImage.bind(this);
    }

    componentDidMount() {
        const {mediaItems, folderList} = this.props;
        // console.log('folderList',folderList)
        // console.log('mediaItems',mediaItems)
        if (mediaItems?.searchValue) {
            const currentFolder = folderList[folderList.length - 1];
            currentFolder && this.getFiles(currentFolder?.id);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {isOpen, appendMedias} = this.props;
        if (!prevProps.isOpen && isOpen && Array.isArray(appendMedias)) {
            this.setState({
                selectedImages: appendMedias
            })
        }
    }

    async getFiles(parent = null, searchValue = '') {
        await this.setState({fetching: true})
        await this.props.GetMedia({parent, searchValue});
        this.setState({fetching: false, searchValue})
    }

    selectImage(item, isSelected) {
        const {multiSelect} = this.props;
        const {selectedImages} = this.state;
        if (multiSelect) {
            this.setState({
                selectedImages: isSelected ? selectedImages.filter(m => m.id !== item.id) : [item, ...selectedImages]
            })
        } else {
            this.setState({
                selectedImages: isSelected ? [] : [item]
            })
        }
    }

    insertMedia() {
        const {selectedImages} = this.state;
        this.props.getMedia(selectedImages, this.props?.mediaType);
        this.closeModal();
    }

    insertWithDoubleClick(item) {
        this.props.getMedia([item], this.props?.mediaType);
        this.closeModal();
    }

    closeModal() {
        this.setState({
            selectedImages: []
        });
        this.props.closeModal();
    }

    openFolder(folderData) {
        const currentFolderList = this.props.folderList || [];
        setFolderList([...currentFolderList, folderData])

        this.getFiles(folderData.id)
    }

    changeFolder = (id) => {
        const {folderList} = this.props;
        if (id === null) {
            this.getFiles()
            setFolderList([])
        } else {
            const newList = [];
            folderList.some(f => {
                newList.push(f);
                return f.id === id
            });
            const currentFolder = newList?.[newList.length - 1]
            setFolderList(newList)
            this.getFiles(currentFolder?.id)
        }
    };

    loadMoreItems() {
        const {mediaItems} = this.props;
        // console.log('load', mediaItems.hasMore)
        mediaItems.hasMore && this.props.GetMedia({
            parent: mediaItems.parent,
            searchValue: mediaItems.searchValue,
            offset: mediaItems.itemsList.length,
            reset: false
        });
    }

    getSearchValue({value}) {
        const {folderList} = this.props;
        const currentFolder = folderList?.[folderList?.length - 1];

        this.setState({searchValue: value})
        if (!value) {
            this.getFiles(currentFolder?.id);
        }
    }

    search() {
        const {folderList} = this.props;
        const currentFolder = folderList?.[folderList?.length - 1];
        const {searchValue} = this.state;
        this.getFiles(currentFolder?.id, searchValue);
    }


    render() {
        const {isOpen, mediaItems, acceptTypes, folderList, mediaView, uploadProgresses} = this.props;
        const {
            selectedImages, searchValue, filesIsChoose, addingFiles, uploading, currentUploadingId,
            addFileCardOpen, chooseType, errors, name, url, loadingGetFileByUrl
        } = this.state;
        let itemsList = mediaItems ? mediaItems.itemsList : [];
        const { progress = 0, status = null } = uploadProgresses[currentUploadingId] || {}
        return <Modal
            visible={isOpen}
            onCancel={this.closeModal}
            wrapClassName={"custom-modal"}>

            <div className="media-selector-modal">
                {
                    filesIsChoose ?
                        <MediaUploader addingFiles={addingFiles}
                                       uploading={uploading}
                                       status={status}
                                       progress={progress}
                                       errors={errors}
                                       addLogoToImage={this.addLogoToImage}
                                       getMedias={this.getMedias}
                                       uploadMedias={this.uploadMedias}
                                       cacnel={this.closeAddMedia}/> :
                        <div className="media-selector-modal-inner">
                            <div className="selector-modal-header">
                                <div className="selector-modal-title">
                                    <h3>
                                            <span className={'path-item'} onClick={() => this.changeFolder(null)}>
                                                Ֆայլեր
                                            </span>
                                        {
                                            folderList && !!folderList.length && folderList.map((folder) => {
                                                return <>
                                                    <span> / </span>
                                                    <span className={'path-item'}
                                                          onClick={() => this.changeFolder(folder.id)}>
                                                            {folder.name}
                                                        </span>
                                                </>
                                            })
                                        }
                                    </h3>
                                </div>
                                <InputGroup
                                    inputType={"input"}
                                    type={'text'}
                                    name={'searchValue'}
                                    value={searchValue}
                                    placeholder={'Փնտրել...'}
                                    onKeyDown={(e) => e && e.key === 'Enter' && this.search()}
                                    onChange={this.getSearchValue}>
                                    <div className={'search-icon'} onClick={this.search}>
                                        <img src={iconSearch} alt=""/>
                                    </div>
                                </InputGroup>
                                <button className="selector-modal-closing-btn"
                                        onClick={this.closeModal}>
                                    <img src={iconCloseDark}
                                         className="close_icon" alt=""/>
                                </button>
                            </div>
                            <div className={'action-btns'}>
                                <div className="right-wrapper">
                                    <AddMediaFolder folderList={folderList}
                                                    folderDropDownClassName={'in-selector-modal'}/>
                                    <div className={'media-upload-in-selector-modal'}>
                                        <Dropdown placement="bottomLeft"
                                                  trigger={["click"]}
                                                  className={"add-file-btn"}
                                                  overlayClassName={`add-file-dropdown in-selector-modal`}
                                                  onVisibleChange={this.toggleAddFileCard}
                                                  visible={addFileCardOpen}
                                                  overlay={
                                                      <div className={'add-file-dropdown-card'}>
                                                          {!chooseType && <div className={'type-select-icons'}>

                                                              <div className={"dropdown-card-img-wrapper"}
                                                                   onClick={this.triggerFileUpload}>
                                                                  <img
                                                                      src={iconComputer}
                                                                      alt={''}/>
                                                                  <span>Ներբեռնել համակարգչից</span>
                                                              </div>
                                                              {/*<div className={"dropdown-card-img-wrapper"}*/}
                                                              {/*     onClick={this.chooseUploadType}>*/}
                                                              {/*    <img*/}
                                                              {/*        src={iconUrl}*/}
                                                              {/*        alt={''}/>*/}
                                                              {/*    <span>Ներբեռնել հղման միջոցով</span>*/}

                                                              {/*</div>*/}
                                                          </div>}
                                                          {chooseType === UPLOAD_TYPES.URL &&
                                                          <div>
                                                              <div className={"form-wrapper"}>
                                                                  <InputGroup inputType={"input"}
                                                                              type={"text"}
                                                                              placeholder={"Հղում"}
                                                                              name={"url"}
                                                                              value={url}
                                                                              maxLength={500}
                                                                              required={true}
                                                                              error={errors.url}
                                                                              onChange={this.getFileUploadByUrlValues}>
                                                                      <img className={"placeholder-img"}
                                                                           src={iconUrl}
                                                                           alt=""/>
                                                                  </InputGroup>
                                                                  <InputGroup inputType={"input"}
                                                                              type={"text"}
                                                                              placeholder={"Անուն"}
                                                                              name={"name"}
                                                                              value={name}
                                                                              maxLength={100}
                                                                              error={errors.name}
                                                                              required={true}
                                                                              onChange={this.getFileUploadByUrlValues}>
                                                                      <img className={"placeholder-img"}
                                                                           src={iconFile}
                                                                           alt=""/>
                                                                  </InputGroup>
                                                              </div>
                                                              <div className="buttons-wrapper">
                                                                  <LinkButton title="Չեղարկել"
                                                                              className={"bg-white"}
                                                                              cb={this.cancelAddFileByUrl}/>
                                                                  <LinkButton title="Ավելացնել"
                                                                              className={"bg-green"}
                                                                              loadingWithTitle
                                                                              loading={loadingGetFileByUrl}
                                                                              cb={this.addFile}/>
                                                              </div>
                                                          </div>}
                                                      </div>

                                                  }>
                                            <div>
                                                <img src={iconNewFile}
                                                     alt=""/>
                                                Ավելացնել Ֆայլ
                                            </div>
                                        </Dropdown>
                                        <input
                                            style={{display: 'none'}}
                                            type="file"
                                            ref={this.inputElement}
                                            multiple={true}
                                            onChange={this.onDropFile}
                                            onClick={this.onUploadClick}
                                        />
                                    </div>
                                </div>
                                <div className={"toggle-track-view"}>
                                    <LinkButton
                                        className={`list-view-button ${mediaView ? 'selected' : ''}`}
                                        title={<img src={iconList} alt=""/>}
                                        cb={() => toggleMediaView(true)}/>
                                    <LinkButton
                                        className={`list-view-button ${!mediaView ? 'selected' : ''}`}
                                        title={<img src={iconGrid} alt=""/>}
                                        cb={() => toggleMediaView(false)}/>
                                </div>
                            </div>
                            <div className={'medias'}>
                                <InfiniteScroll
                                    hasMore={mediaItems.hasMore}
                                    loadMore={this.loadMoreItems}
                                    className={'media-items-wrapper'}
                                    pageStart={0}
                                    threshold={1000}
                                    useWindow={false}
                                    initialLoad={false}>

                                    {itemsList && !!itemsList.length && itemsList.map((item, index) => {
                                        const isSelected = selectedImages.find(v => v.id === item.id);
                                        const disabled = acceptTypes.every(type => type !== item.extension)
                                        if (item.type === "FOLDER") {
                                            return <MediaFolderCard key={item.id}
                                                                    openFolder={this.openFolder}
                                                                    item={item}
                                                                    listView={mediaView}/>
                                        }
                                        return <MediaFileCard key={item.id}
                                                              selectable={true}
                                                              isSelected={isSelected}
                                                              selectImage={this.selectImage}
                                                              withCopy={true}
                                                              disabled={disabled}
                                                              onDoubleClick={this.insertWithDoubleClick}
                                                              item={item}
                                                              listView={mediaView}/>
                                    })}

                                </InfiniteScroll>
                            </div>
                            <div className="choose-button">
                                <LinkButton title={'Ընտրել'}
                                            disabled={!selectedImages.length}
                                            cb={this.insertMedia}/>
                            </div>

                        </div>}
            </div>
        </Modal>
    }
}

MediaSelectorModal.defaultProps = {
    mediaType: MEDIA_MODAL_TYPES.MEDIA_MAIN,
    multiSelect: false,
}
const mapDispatchToProps = {
    GetMedia,
    UploadFile
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaSelectorModal);
