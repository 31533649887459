import BuildingAFloor1 from "../assets/images/buildings/building-A/floor-2.png";
import BuildingAFloor2 from "../assets/images/buildings/building-A/floor-2.png";
import BuildingBFloor1 from "../assets/images/buildings/building-B/floor-2.png";
import BuildingBFloor2 from "../assets/images/buildings/building-B/floor-1.png";

export const floorsImages = {
    A1: BuildingAFloor1,
    A2: BuildingAFloor2,
    A3: BuildingAFloor2,
    A4: BuildingAFloor2,
    A5: BuildingAFloor2,
    A6: BuildingAFloor2,
    A7: BuildingAFloor2,
    A8: BuildingAFloor2,
    A9: BuildingAFloor2,
    A10: BuildingAFloor2,
    A11: BuildingAFloor2,
    A12: BuildingAFloor2,
    A13: BuildingAFloor2,
    A14: BuildingAFloor2,
    B1: BuildingBFloor1,
    B2: BuildingBFloor2,
    B3: BuildingBFloor2,
    B4: BuildingBFloor2,
    B5: BuildingBFloor2,
    B6: BuildingBFloor2,
    B7: BuildingBFloor2,
    B8: BuildingBFloor2,
    B9: BuildingBFloor2,
    B10: BuildingBFloor2,
    B11: BuildingBFloor2,
    B12: BuildingBFloor2,
    B13: BuildingBFloor2,
    B14: BuildingBFloor2,
    B15: BuildingBFloor2,
    B16: BuildingBFloor2,
}