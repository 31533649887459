// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Switch} from "antd";

// Import Styles
import '../../assets/styles/containerStyles/floor.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper';
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {LinkButton} from "../../components/buttons/buttons";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    GetAreas,
    UpdateFloorById,
    clearAreasData,
    UpdateAreasPositions,
    GetFloorById, DeleteArea,
} from "../../redux/actions";
import {deleteItem, priceWithCommas, toggleStateField} from "../../utils/helperFunctions";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import Nestable from "react-nestable";
import {NestableItem} from "../../components/cards/nestableItem";


class FloorDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            floor: null,
            inactive: false,
            mediaMain: undefined,
            mediaModalOpen: false
        }
        this.deleteArea = deleteItem.bind(this, props.DeleteArea, props?.floorById?.number > 0 ? 'Բնակարանը' : 'Ավտոկայանտեղին');
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.getInputValues = this.getInputValues.bind(this);
        this.onPositionChange = this.onPositionChange.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.editFloor = this.editFloor.bind(this);
    }

    componentDidMount() {
        const {id} = this.props;

        this.props.GetFloorById(id).then((res) => {
            this.props.GetAreas(res?.id);
        })
    }

    getInputValues({value}) {
        this.props.UpdateFloorById(this.props?.id, {inactive: value})
    }

    getMedia(mediaArray) {
        this.setState({
            mediaMain: mediaArray[0],
        })
    }

    deleteMedia() {
        this.setState({
            mediaMain: null
        })
    }

    async onPositionChange(items, item) {
        const movedItemId = item.id;
        const {areas, parking, floorById} = this.props;
        const itemsList = floorById?.number > 0 ? areas : parking
        const positionedItems = items.map((item, index) => {
            return {id: item.id, position: index + 1}
        });
        if (~itemsList.findIndex(m => m.id === movedItemId) &&
            ~positionedItems.findIndex(m => m.id === movedItemId)) {
            // first level house was re-positioned in first level
            await this.props.UpdateAreasPositions(positionedItems);
            this.props.GetAreas(this.props.id);
        }
    }

    editFloor() {
        const {id,} = this.props;
        const {mediaMain} = this.state;
        const reqData = {
            mediaMain: mediaMain?.id || null
        }
        this.props.UpdateFloorById(id, reqData).then(_ => {
            // this.setState({
            //     floor: {
            //         ...floor,
            //         mediaMain
            //     }
            // })
        })
    }

    getNestableItems(list, floor) {
        const {priceFormattingRegExp} = this.props
        const items = [];
        list && !!list.length && list.forEach((item, index) => {

            items.push({
                id: item.id,
                index: index,
                showIndex: true,
                customContent: <>
                    <div className={'house-content'}>
                        <div className={'left-content'}>
                            <span>Համար։ {item?.areaNumber}</span>
                            <span style={{color: item?.badge?.color}}>{item.badge?.title}</span>
                        </div>
                        {item.roomsCount && <span>Սենյակներ։ {item.roomsCount}</span>}
                        <span>Մակերես։ {item.area}</span>
                        <span>Գին։ {priceWithCommas(priceFormattingRegExp, item.price) || "-"}</span>
                    </div>

                </>,
                withImage: true,
                largeItem: true,
                path: item.medias[0]?.path,
                link: {
                    pathname: `/dashboard/house-details/${item.id}`,
                    state: {floor}
                },
                deleteCb: () => this.deleteArea(item.id)
            })
        });
        return items;
    }

    render() {
        const {areas, floorById} = this.props;
        const { mediaModalOpen} = this.state;
        const itemsList = areas
        let items = this.getNestableItems(itemsList, floorById);

        return <PageWrapper pageTitle={`Հարկ: ${floorById?.number || ''}`}>
            <section className="floor-details">
                <div className="fixed-top-right">
                    <label>{!floorById?.inactive ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!floorById?.inactive}
                            onChange={(checked) => this.getInputValues({
                                name: "inactive",
                                value: !checked,
                                haveChanges: floorById?.inactive !== !checked
                            })}/>
                </div>
                {/*<div className="top-side">
                    <label>Նկար </label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn`}
                                cb={this.toggleMediaModal}/>
                </div>
                {mediaMain && mediaMain?.path && <div className={'room-image-wrapper'}>
                    <img src={generateFileMediaUrl(mediaMain?.path)} alt=""/>
                </div>}
                <div className="flex-wrapper-right">
                    <LinkButton title={'Փոփոխել'}
                                loading={requestLoading}
                                disabled={floor?.mediaMain?.id === mediaMain?.id}
                                cb={this.editFloor}/>
                </div>*/}
                <div style={{marginBottom: '20px'}}>
                    <LinkButton title={'Ավելացնել'}
                                link={{
                                    pathname: `/dashboard/create-house`,
                                    state: {
                                        floor: floorById
                                    }
                                }}/>
                </div>

                <div className={'houses-list'}>
                    {items && items.length !== 0 &&
                        <Nestable
                            items={items}
                            maxDepth={1}
                            onChange={this.onPositionChange}
                            renderItem={NestableItem}
                        />}
                    {/* {areas?.map(house => {
                        return <div className={'house-card'}>
                            <Link className="inner-box" to={{
                                pathname: `/dashboard/house-details/${house.id}`,
                                state: { floor }
                            }}>
                                <img src={generateFileMediaUrl(house?.mediaMain?.path)} alt=""/>
                                <div className={'floor-info'}>
                                    <div className={'info'}>
                                        <span>Համար։ &nbsp;</span>
                                        <span> {house?.number}</span>
                                    </div>
                                    <div className={'info'}>
                                        <span>Ստատուս։ &nbsp;</span>
                                        <span> {house?.badge?.title}</span>
                                    </div>
                                </div>
                                <LinkButton cb={(e)=>{
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.deleteArea(house.id)
                                }}
                                            title={<MaterialIcon icon={'remove'}/>}
                                            className={' delete-button bg-red'}/>
                            </Link>

                        </div>
                    })}
*/}
                </div>

            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetAreas,
    UpdateFloorById,
    clearAreasData,
    UpdateAreasPositions,
    GetFloorById,
    DeleteArea
};

export default connect(mapStateToProps, mapDispatchToProps)(FloorDetails)
