// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

// Import Styles
import '../../assets/styles/containerStyles/floor.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper';

// Import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import { setFloors , clearAreasData } from "../../redux/actions";
import ItemsList from "../../components/ItemsList";

class Floors extends Component {

    componentDidMount(){
        const { buildings, id } = this.props

        const floors = buildings.find(b => b.id === id)?.floors || [];
        this.props.setFloors(floors);
        this.props.clearAreasData()
        const firthFloor = floors?.map(item => item?.number === 1)
    }

    render(){
        let {floors, buildings, id, requestLoading} = this.props;
        const building = buildings.find(b => b.id === id)?.slug;
        let itemListInfo = floors?.map((floor) => {
            const {id, number, soldAreasCount, housesCount, availableAreasCount} = floor;
            if(number === 1){
                return {
                    id:'/',
                    values: [
                        number,
                        housesCount,
                        availableAreasCount,
                        soldAreasCount
                    ],
                }
            }else {
                return {
                    id,
                    values: [
                        number,
                        housesCount,
                        availableAreasCount,
                        soldAreasCount
                    ],
                }
            }
        });
        let itemList = itemListInfo.splice(2,1)
        let itemListHeaderInfo = {
            generalInfo: ["Նկար", "Հարկ", "Բն․-ների քանակ", "Ազատ բն․-ներ", "Զբաղված բն․-ներ"],
            largeItems: [],
            actions: ["details"],
        };
        return <PageWrapper pageTitle={'Հարկեր'}>
                  <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                                   itemListInfo={itemListInfo}
                                   loading={requestLoading}
                                   detailsLink={`dashboard/floor-details`}
                                   withImage={true}
                                   building={building}
                        />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    setFloors,
    clearAreasData
};

export default connect(mapStateToProps, mapDispatchToProps)(Floors)
