import { PROJECT_CONSTS, AUTH_CONSTS } from "../constants";

export const initialState = {
    projects: [],
    projectById: null,
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case PROJECT_CONSTS.GET_PROJECTS:
            return {
                ...state,
                projects: action.payload,
            };
        case PROJECT_CONSTS.GET_PROJECT_BY_ID:
            return {
                ...state,
                projectById: action.payload,
            };
        case PROJECT_CONSTS.DELETE_PROJECT_BLOCK_BY_ID:
            return {
                ...state,
                projectById: state.projectById.blocks.filter(block => block.id !== action.payload.id)
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState
        default:
            return state;
    }
}
