import { request, _urlSubscribers } from "../api";
import { SUBSCRIBERS_CONSTS } from "../constants";
import { notificationSuccess } from "../../components/uiElements/Notifications";
import { history } from "../../configs/history";
import {LIMIT_GET_SUBSCRIBERS} from "../../constants/constLimitCounts";


export const GetSubscribers = ({
                                   reset = true,
                                   offset = 0,
                                   limit = LIMIT_GET_SUBSCRIBERS
                               }={}) => {
    const requestData = {
        url: `${_urlSubscribers}?offset=${offset}&limit=${limit}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: SUBSCRIBERS_CONSTS.GET_SUBSCRIBERS,
                    payload: {
                        data: data,
                        hasMore: data.length === limit,
                        reset
                    }
                });
            })
    }
};

export const DeleteSubscriberById = id => {
    const requestData = {
        url:  `${_urlSubscribers}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: SUBSCRIBERS_CONSTS.DELETE_SUBSCRIBER_BY_ID,
                    payload: id
                });
            })
            .catch(() => {})
    }
};

export const SendEmailToSubscribers = (data) => {
    const requestData = {
        url:  `${_urlSubscribers}`,
        token: true,
        method: "POST",
        data
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                notificationSuccess({
                    description: "Էլ․ նամակը հաջողությամբ ուղարկվեց!"
                })
                history.push('/subscribers')
            })
            .catch(() => {})
    }
};
